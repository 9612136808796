import {
  animate,
  state,
  style,
  transition,
  trigger,
  query
} from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        animate('150ms ease-in-out', style({ opacity: 1 }))
      ],
      {
        optional: true
      }
    ),
    query(
      ':leave',
      [
        style({ opacity: 1 }),
        animate('150ms ease-in-out', style({ opacity: 0 }))
      ],
      {
        optional: true
      }
    )
  ])
]);

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
  state('in', style({ opacity: '1' })),
  transition('void => *', [
    style({ opacity: '0' }),
    animate('100ms ease-in-out')
  ]),
  transition('* => void', [
    animate('50ms ease-in-out', style({ opacity: '0' }))
  ])
]);
