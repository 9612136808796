import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white
} from './helpers';

export const luxurybrand: TrackingPageModel = {
  languageMenu: getLanguageMenu('Merriweather', '#ffffffff'),
  searchPageInput: {
    backgroundColor: '#ffffff',
    borderColor: '#c2a23aff',
    borderRadius: 0,
    borderWidth: 2,
    shadow: 'none',
    ...getFontConfig('Ubuntu', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Merriweather', '#ffffffff', '700')
  },
  background: {
    color: white,
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/luxury-cosmetics.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: '#ffffff',
    linksColor: '#c2a23aff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Merriweather', '#181818ff', '700')
  },
  orderCard: {
    backgroundColor: '#ffffff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#c2a23aff',
    borderRadius: 0,
    borderWidth: 2,
    shadow: 'none',
    ...getFontConfig('Merriweather', '#2E4068')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 25,
    ...getFontConfig('Montserrat', '#c2a23aff', '700')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Merriweather', '#000000', '700')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 11,
    ...getFontConfig('Montserrat', '#646464ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Montserrat', '#952b2bff', '700')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    scroll: false,
    itemsLimit: 4,
    borderColor: '#e8e8e8ff',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: '#ffffff',
    shadow: 'none',
    button: {
      on: true,
      backgroundColor: '#c2a23aff',
      borderColor: '#ffffffff',
      borderRadius: 0,
      borderWidth: 1,
      shadow: 'none',
      ...getFontConfig('Poppins', white)
    },
    itemCard: {
      backgroundColor: '#ffffff00',
      borderColor: '#2E4068',
      borderRadius: 4,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 19,
        ...getFontConfig('Montserrat', '#000000', '700')
      },
      info: {
        alignment: 'left',
        fontSize: 13,
        ...getFontConfig('Montserrat', '#787878ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: '#dbc352ff',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Merriweather', '#ffffffff')
  },
  detailsButton: {
    backgroundColor: '#c2a23aff',
    borderColor: '#5780ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Montserrat', white, '700')
  },
  trackButton: {
    backgroundColor: '#c2a23aff',
    borderColor: '#ee722700',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Merriweather', white)
  },
  pageScroll: {
    scrollColor: '#2E4068',
    borderRadius: 2,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/luxury-cosmetics-logo.png')),
  socialIcons: getSocialIcons('#ffffff'),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#ffffff',
    borderColor: '#CED2DC',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    sidebarField: null,
    ...getFontConfig('Merriweather', '#000000ff', '700')
  },
  footer: {
    linksColor: '#c2a23aff',
    linksUnderline: false,
    backgroundColor: '#242424ff',
    ...getFooterItems(),
    ...getFontConfig('Montserrat', '#ffffffff')
  },
  header: {
    backgroundColor: '#181818ff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: false,
    backgroundColor: '#2c2c2cff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffffff',
      borderColor: '#ffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#c2a23aff',
        borderColor: '#5780ff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Montserrat', white, '700')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Merriweather', '#000000ff', '700')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Ubuntu', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Lora', '#254c7bff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('luxury-brand'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#c2a23aff',
      arrowSize: 30
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#2c2c2cff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffffff',
      borderColor: '#ffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#c2a23aff',
        borderColor: '#5780ff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Montserrat', white, '700')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Merriweather', '#000000ff', '700')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Ubuntu', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Lora', '#254c7bff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('luxury-brand'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#c2a23aff',
      arrowSize: 30
    }
  },
  seoTitle: ''
};
