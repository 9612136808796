import { NgModule } from '@angular/core';
import { DateFormat } from './format-date.pipe';
import { AssignPipe } from 'app/core/pipes/assign.pipe';
import { FontFamilyPipe } from 'app/core/pipes/font-family.pipe';
import { DateUtcFormat } from 'app/core/pipes/format-date-utc.pipe';

@NgModule({
  declarations: [DateFormat, DateUtcFormat, AssignPipe, FontFamilyPipe],
  exports: [DateFormat, DateUtcFormat, AssignPipe, FontFamilyPipe]
})
export class CorePipesModule {}
