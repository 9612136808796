import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white
} from './helpers';

export const toystore: TrackingPageModel = {
  languageMenu: getLanguageMenu('Poppins', '#000000ff'),
  searchPageInput: {
    backgroundColor: '#ffffff',
    borderColor: '#ced4da',
    borderRadius: 21,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Ubuntu', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 21,
    ...getFontConfig('Nunito Sans', '#0a0f3cff')
  },
  background: {
    color: white,
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/toy-store.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: '#ffffff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 4,
    borderWidth: 0,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Nunito', '#0a0f3cff', '700')
  },
  orderCard: {
    backgroundColor: '#ffffff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#99d7e4ff',
    borderRadius: 25,
    borderWidth: 3,
    shadow: 'none',
    ...getFontConfig('Poppins', '#2E4068')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Nunito Sans', '#0091ffff', '700')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Nunito Sans', '#0a0f3bff', '700')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Nunito', '#696969ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Nunito Sans', '#e96262ff', '700')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    scroll: false,
    itemsLimit: 4,
    borderColor: '#5780ff',
    borderWidth: 0,
    borderRadius: 15,
    backgroundColor: '#99d7e4ff',
    shadow: 'none',
    button: {
      on: true,
      backgroundColor: '#2cb34dff',
      borderColor: '#2cb34dff',
      borderRadius: 22,
      borderWidth: 0,
      shadow: 'none',
      ...getFontConfig('Nunito Sans', white)
    },
    itemCard: {
      backgroundColor: '#ffffff00',
      borderColor: '#2E4068',
      borderRadius: 4,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Nunito Sans', '#0a0f3cff', '700')
      },
      info: {
        alignment: 'left',
        fontSize: 15,
        ...getFontConfig('Noto Sans', '#0a0f3cff', '500')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#fba129ff',
    borderColor: '#e7eaf1ff',
    borderRadius: 21,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Poppins', '#ffffffff', '700')
  },
  detailsButton: {
    backgroundColor: '#99d7e4ff',
    borderColor: '#5780ff',
    borderRadius: 22,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Ubuntu', white)
  },
  trackButton: {
    backgroundColor: '#fba129ff',
    borderColor: '#ee7227',
    borderRadius: 21,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Nunito Sans', white, '700')
  },
  pageScroll: {
    scrollColor: '#2E4068',
    borderRadius: 2,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/toy-store-logo.png')),
  socialIcons: getSocialIcons('#272727ff', false),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#ffffff',
    borderColor: '#CED2DC',
    borderRadius: 20,
    borderWidth: 0,
    shadow: 'none',
    sidebarField: null,
    ...getFontConfig('Poppins', '#202632ff', '700')
  },
  footer: {
    linksColor: '#ffffffff',
    linksUnderline: false,
    backgroundColor: '#2cb34dff',
    ...getFooterItems(),
    ...getFontConfig('Poppins', '#ffffffff')
  },
  header: {
    backgroundColor: '#ffffff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: false,
    backgroundColor: '#86c9d7ff',
    borderColor: '#d5d9e1',
    borderRadius: 25,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: 15,
      borderWidth: 3,
      shadow: 'none',
      button: {
        backgroundColor: '#2cb34dff',
        borderColor: '#5780ff',
        borderRadius: 21,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Poppins', white, '700')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Nunito Sans', '#0a0f3bff', '700')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Poppins', '#ff7272ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Poppins', '#4a6635ff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('toy-store'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#ffffffff',
      arrowSize: 35
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#86c9d7ff',
    borderColor: '#d5d9e1',
    borderRadius: 25,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: 15,
      borderWidth: 3,
      shadow: 'none',
      button: {
        backgroundColor: '#2cb34dff',
        borderColor: '#5780ff',
        borderRadius: 21,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Poppins', white, '700')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Nunito Sans', '#0a0f3bff', '700')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Poppins', '#ff7272ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Poppins', '#4a6635ff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('toy-store'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#ffffffff',
      arrowSize: 35
    }
  },
  seoTitle: ''
};
