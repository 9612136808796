import {
  Output,
  EventEmitter,
  Input,
  HostBinding,
  Directive
} from '@angular/core';

@Directive()
export abstract class HeaderMenuComponent {
  @Output()
  toggleMenu = new EventEmitter<boolean>();
  @Input()
  menuOpen: boolean;
  @HostBinding('class')
  navItem = 'nav-item-component';
}
