import { Output, EventEmitter, Input, Directive } from '@angular/core';
import { DestroyChecker } from './destroy-checker';

@Directive()
export abstract class TableHeaderFilter extends DestroyChecker {
  @Output() public readonly clearSortFiltering = new EventEmitter();
  @Output() public readonly toggleSorting = new EventEmitter();
  @Output() public readonly toggleFiltering = new EventEmitter();

  @Input() column: any;
  @Input() sortAndFilter: any;
  clear(): void {}
}
