import { TrackingPageModel } from '../tracking-page.model';
import {
  getImage,
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white,
  getProductItems
} from './helpers';

export const simple: TrackingPageModel = {
  languageMenu: getLanguageMenu('Nunito', '#333333ff'),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('Nunito', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Nunito', '#333333ff')
  },
  background: {
    color: white,
    useBackgroundImage: false,
    imageUrl: getImage('/assets/img/tracking-page/Img_map_white.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: white,
    headerBackground: white,
    footerBackground: white,
    borderColor: '#33333333',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    linksColor: '#333333ff',
    linksUnderline: true,
    ...getFontConfig('Nunito', '#333333ff')
  },
  orderCard: {
    borderRadius: 0,
    borderWidth: 1,
    borderColor: '#33333333',
    backgroundColor: white,
    shadow: 'none',
    footerBackground: white,
    headerBackground: white,
    linksColor: '#333333ff',
    linksUnderline: true,
    ...getFontConfig('Nunito', '#333333ff')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Montserrat', '#333333ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 18,
    ...getFontConfig('Montserrat', '#333333ff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 13,
    ...getFontConfig('Montserrat', '#a5a5a5ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 14,
    ...getFontConfig('Nunito', '#d33333ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: '#33333333',
    borderRadius: 0,
    borderWidth: 1,
    backgroundColor: '#ffffff',
    button: {
      on: true,
      backgroundColor: '#ffffff',
      borderColor: '#333333ff',
      borderRadius: 0,
      borderWidth: 1,
      shadow: 'none',
      ...getFontConfig('Nunito', '#333333ff')
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: white,
      borderColor: '#33333333',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 18,
        ...getFontConfig('Nunito', '#000000ff')
      },
      info: {
        alignment: 'left',
        fontSize: 11,
        ...getFontConfig('Montserrat', '#333333ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: '#333333ff',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Nunito', '#333333ff')
  },
  detailsButton: {
    on: null,
    backgroundColor: '#333333ff',
    borderColor: '#00ccff00',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Nunito', white)
  },
  trackButton: {
    on: null,
    backgroundColor: '#333333ff',
    borderColor: '#ee7227',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Nunito', white)
  },
  pageScroll: {
    scrollColor: '#00000033',
    borderRadius: 4,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(
    getImage('/assets/tracking-page-themes/5c813b3a0c88f_logo-360x.png')
  ),
  socialIcons: getSocialIcons('#333333ff'),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: white,
    borderColor: '#33333333',
    borderRadius: 0,
    borderWidth: 1,
    sidebarField: 'orderNumbers',
    shadow: 'none',
    ...getFontConfig('Nunito', '#333333ff')
  },
  footer: {
    linksColor: '#333333ff',
    linksUnderline: true,
    backgroundColor: white,
    ...getFontConfig('Nunito', '#333333ff'),
    ...getFooterItems()
  },
  header: {
    backgroundColor: white
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#33333333',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#33333333',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#333333ff',
        borderColor: '#00ccff00',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Nunito', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 19,
        ...getFontConfig('Montserrat', '#333333ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Nunito', '#fa0000ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Nunito', '#333333ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#333333ff',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#33333333',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#33333333',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#333333ff',
        borderColor: '#00ccff00',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Nunito', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Montserrat', '#333333ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Nunito', '#fa0000ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Nunito', '#333333ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#333333ff',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
