import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getImage,
  getLanguageMenu,
  white
} from './helpers';

export const number22: TrackingPageModel = {
  languageMenu: getLanguageMenu('Lato', '#a8a8a8ff'),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('Lato', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Lato', '#a9a9a9ff')
  },
  background: {
    color: '#000000ff',
    useBackgroundImage: false,
    imageUrl: getImage('/assets/img/tracking-page/Img_map_white.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    linksColor: '#00b490ff',
    linksUnderline: false,
    backgroundColor: '#000000ff',
    headerBackground: '#000000ff',
    footerBackground: '#000000ff',
    borderColor: '#00b490ff',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Lato', '#a9a9a9ff')
  },
  orderCard: {
    borderRadius: 0,
    borderWidth: 0,
    borderColor: '#00b490ff',
    backgroundColor: '#121212ff',
    shadow: 'none',
    footerBackground: '#000000ff',
    headerBackground: '#000000ff',
    linksColor: '#00b490ff',
    linksUnderline: false,
    ...getFontConfig('Lato', '#a9a9a9ff')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Lato', '#a9a9a9ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 19,
    ...getFontConfig('Lato', '#ffffffff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 11,
    ...getFontConfig('Lato', '#515151ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Lato', '#c25d2fff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: '#ffffff00',
    borderRadius: 0,
    borderWidth: 0,
    backgroundColor: '#232323ff',
    button: {
      on: true,
      backgroundColor: '#585858ff',
      borderColor: '#00b49000',
      borderRadius: 0,
      borderWidth: 2,
      shadow: 'none',
      ...getFontConfig('Lato', '#ffffffff')
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: '#00000000',
      borderColor: '#00b490ff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Lato', '#ffffffff', '700')
      },
      info: {
        alignment: 'left',
        fontSize: 15,
        ...getFontConfig('Lato', '#a9a9a9ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: '#00b490ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Lato', '#ffffffff')
  },
  detailsButton: {
    on: null,
    backgroundColor: '#00b490ff',
    borderColor: '#5780ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Lato', '#000000ff')
  },
  trackButton: {
    on: null,
    backgroundColor: '#00b490ff',
    borderColor: '#ee7227',
    borderRadius: 1,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Lato', '#000000ff')
  },
  pageScroll: {
    scrollColor: '#ffffff80',
    borderRadius: 2,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(
    getAsset(
      '/images/themes/5c98eaee3b978_no22-2x-d7f3b9d1-65de-42ec-98a8-729752862a81-x16-2x.png'
    )
  ),
  socialIcons: getSocialIcons('#00b490ff'),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#232323ff',
    borderColor: '#232323ff',
    borderRadius: 0,
    borderWidth: 0,
    sidebarField: 'orderNumbers',
    shadow: 'none',
    ...getFontConfig('Lato', '#a9a9a9ff')
  },
  footer: {
    linksColor: '#00b490ff',
    linksUnderline: false,
    backgroundColor: '#000000ff',
    ...getFooterItems(),
    ...getFontConfig('Lato', '#a9a9a9ff')
  },
  header: {
    backgroundColor: '#191c1bff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: '#000000ff',
    borderColor: '#00b490ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#232323ff',
      borderColor: '#232323ff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#00b490ff',
        borderColor: '#5780ff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Open Sans', '#000000ff')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Lato', '#a9a9a9ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Lato', '#a9a9a9ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Lato', '#ffffffff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#00b490ff',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#000000ff',
    borderColor: '#00b490ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#232323ff',
      borderColor: '#00b490ff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#00b490ff',
        borderColor: '#5780ff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Lato', '#000000ff')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Lato', '#a9a9a9ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Lato', '#a9a9a9ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Lato', '#ffffffff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#00b490ff',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
