import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { RouterTypes } from '../actions';
import { toPayload, isObjectOnly } from 'app/helpers';
import { tap } from 'rxjs/operators';
import { cond } from 'ramda';
import { isArray } from 'lodash';

@Injectable()
export class RouterEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private location: Location
  ) {}

  @Effect({ dispatch: false })
  public readonly go$: Observable<any> = this.actions$.pipe(
    ofType(RouterTypes.GO),
    toPayload(),
    tap(this.navigate())
  );

  @Effect({ dispatch: false })
  public readonly back$: Observable<any> = this.actions$.pipe(
    ofType(RouterTypes.BACK),
    tap(() => this.location.back())
  );

  private navigate() {
    return cond([
      [isArray, data => this.router.navigate(data)],
      [
        isObjectOnly,
        ({ url, options }) => {
          this.router.navigate(url, options);
        }
      ]
    ]);
  }
}
