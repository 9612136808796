import { DefaultThemeIds } from './themes';
import { THEMES } from 'app/core/tracking-page/themes';
import { reduce, isArray, isEmpty } from 'lodash';
import { isDefined, isObjectOnly } from 'app/helpers';

export type TrackingPageItemWithFont =
  | 'sidebar'
  | 'card'
  | 'footer'
  | 'searchPageTitle'
  | 'searchPageInput'
  | 'searchButton'
  | 'detailsButton'
  | 'trackButton'
  | 'mainProductsFeed.card.button'
  | 'mainProductsFeed.card.title'
  | 'mainProductsFeed.card.regularPrice'
  | 'mainProductsFeed.card.discountedPrice'
  | 'sidebarProductsFeed.card.button'
  | 'sidebarProductsFeed.card.title'
  | 'sidebarProductsFeed.card.regularPrice'
  | 'sidebarProductsFeed.card.discountedPrice';

export interface TrackingPageFont {
  font: {
    fontColor: string;
    fontFamily: string;
    fontStyle?: string;
    customFontName: string;
    customFontUrl: string;
    customFontUsed: boolean;
  };
}

export interface TrackingPageSidebar extends TrackingPageFont {
  backgroundColor: string;
  sidebarField: string;
  parcelIcon: string;
  groupIcon: string;
  shadow: string;
  borderRadius: number;
  borderColor: string;
  borderWidth: number;
}

export interface TrackingPageButton extends TrackingPageFont {
  on?: boolean;
  backgroundColor: string;
  borderColor: string;
  borderRadius?: number;
  borderWidth?: number;
  shadow?: string;
}

export interface TrackingPageBackground {
  color: string;
  useBackgroundImage: boolean;
  imageUrl: string;
  backgroundMode: string;
  backgroundRepeat: string;
  backgroundPosition: string;
}

export interface TrackingPageFooter extends TrackingPageFont {
  hideTrackmageBranding: boolean;
  links: { title: string; url: string }[];
  backgroundColor: string;
  linksColor: string;
  linksUnderline: boolean;
}

export interface TrackingPageLogo {
  on: boolean;
  imageUrl: string;
  linkUrl: string;
}

export interface TrackingPageLanguageMenu extends TrackingPageFont {
  on: boolean;
  defaultLan: string;
}

export interface SearchPageTitle extends TrackingPageFont {
  fontSize: number;
}

export interface SearchPageInput extends TrackingPageFont {
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
  borderWidth: number;
  shadow: string;
}

export interface TrackingPageCard extends TrackingPageFont {
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
  shadow: string;
  trackingNumberField: string;
  borderWidth: number;
  headerBackground: string;
  footerBackground: string;
  linksColor: string;
  linksUnderline: boolean;
}

export interface TrackingPageScroll {
  scrollColor: string;
  borderRadius: number;
  backgroundColor: string;
}

export interface TrackingPageHeader {
  backgroundColor: string;
}

export interface TrackingPageSocialIcons {
  icons: any[];
  fontColor: string;
  alignment?: string;
  margin?: TrackingPageSocialIconsMargin;
}
export interface TrackingPageSocialIconsMargin {
  bottom: number;
  left: number;
  right: number;
  top: number;
}

export interface TrackingPageText extends TrackingPageFont {
  alignment?: string;
  fontSize: number;
}

export interface TrackingPageProductCard {
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
  borderWidth: number;
  shadow: string;
  button: TrackingPageButton;
  buttonText?: string;
  title: TrackingPageText;
  regularPrice: TrackingPageText;
  discountedPrice: TrackingPageText;
  imageBorderRadius?: number;
  imageHeight?: number;
  imageWidth?: number;
}

export interface TrackingPageProductsFeedItem {
  title: string;
  imageUrl: string;
  buttonUrl: string;
  regularPrice: string;
  discountedPrice: string;
}

export interface TrackingPageSliderOptions {
  autoplay: boolean;
  autoplayDelay: number;
  loop: boolean;
  arrowColor: string;
  arrowSize: number;
}

export interface TrackingPageProductsFeed {
  on?: boolean;
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
  borderWidth: number;
  shadow: string;
  card: TrackingPageProductCard;
  feedUrl: string;
  productItems: any[];
  sliderOptions?: TrackingPageSliderOptions;
}

export interface TrackingPageOrderCard extends TrackingPageFont {
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
  shadow: string;
  borderWidth: number;
  headerBackground: string;
  footerBackground: string;
  linksColor: string;
  linksUnderline: boolean;
}

export interface TrackingPageOrderItemCard {
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
  borderWidth: number;
  info: TrackingPageText;
  shadow: string;
  showAttributes: boolean;
  title: TrackingPageText;
}

export interface TrackingPageOrderItemsFrame {
  borderColor: string;
  borderRadius: number;
  borderWidth: number;
  button: TrackingPageButton;
  itemCard: TrackingPageOrderItemCard;
  itemsLimit: number;
  scroll: boolean;
  backgroundColor: string;
  on: boolean;
  shadow: string;
}
export interface TrackingPageModel {
  languageMenu: TrackingPageLanguageMenu;
  defaultThemeId?: DefaultThemeIds;
  background: TrackingPageBackground;
  card: TrackingPageCard;
  searchButton: TrackingPageButton;
  detailsButton: TrackingPageButton;
  trackButton: TrackingPageButton;
  pageScroll: TrackingPageScroll;
  logo: TrackingPageLogo;
  socialIcons: TrackingPageSocialIcons;
  sidebar: TrackingPageSidebar;
  footer: TrackingPageFooter;
  header: TrackingPageHeader;
  searchPageTitle: SearchPageTitle;
  searchPageInput: SearchPageInput;
  sidebarProductsFeed: TrackingPageProductsFeed;
  mainProductsFeed: TrackingPageProductsFeed;
  scripts: {
    head: string;
    body: string;
  };
  faviconUrl: string;
  seoTitle?: string;
  orderCard: TrackingPageOrderCard;
  orderNumber: TrackingPageText;
  orderStatus: TrackingPageText;
  orderLastUpdated: TrackingPageText;
  orderStatusOn: boolean;
  orderNumberOn: boolean;
  orderLastUpdatedOn: boolean;
  orderItemsFrame: TrackingPageOrderItemsFrame;
  notYetShippedItemsTitle: TrackingPageText;
}

export interface TrackingPageBuilder extends TrackingPageModel {
  defaultThemeId: DefaultThemeIds;
}

export const keysWithDefaultValues = [
  'logo',
  'icons',
  'links',
  'productItems',
  'hideTrackmageBranding'
];

export const withDefaultValues = (
  data: TrackingPageModel,
  defaultTP: any = THEMES['default'],
  useDefaultValues: boolean = false
): TrackingPageModel =>
  reduce(
    defaultTP,
    (acc, value, key) => {
      const dataValue = (data || {})[key];
      if (
        useDefaultValues &&
        keysWithDefaultValues.indexOf(key) !== -1 &&
        isDefined(defaultTP[key]) &&
        (key !== 'logo' ||
          (key === 'logo' && isDefined(defaultTP['logo']['on'])))
      ) {
        return { ...acc, [key]: defaultTP[key] };
      }
      let newValue: any;
      if (isObjectOnly(value)) {
        newValue = withDefaultValues(
          dataValue,
          defaultTP[key],
          useDefaultValues
        );
      } else if (
        isArray(dataValue) &&
        isEmpty(dataValue) &&
        key !== 'icons' &&
        key !== 'links' &&
        key !== 'productItems'
      ) {
        newValue = [...defaultTP[key]];
      } else if (key === 'on' && dataValue === null) {
        newValue = defaultTP[key];
      } else if (key === 'sidebarField' && dataValue === null) {
        newValue = dataValue;
      } else if (dataValue === null || dataValue === undefined) {
        newValue = value;
      } else {
        newValue = dataValue;
      }
      return { ...acc, [key]: newValue };
    },
    {} as any
  );
