import { TrackingPageModel } from '../tracking-page.model';
import {
  getAppImage,
  getAsset,
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white,
  getProductItems
} from './helpers';

export const sparo: TrackingPageModel = {
  languageMenu: getLanguageMenu('PT Sans', '#504e4aff'),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('PT Sans', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('PT Sans', '#504e4aff')
  },
  background: {
    color: white,
    useBackgroundImage: false,
    imageUrl: getAppImage('/assets/img/tracking-page/Img_map_white.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: white,
    headerBackground: white,
    footerBackground: white,
    borderColor: '#504e4a33',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    linksColor: '#9c8773ff',
    linksUnderline: true,
    ...getFontConfig('PT Sans', '#504e4aff')
  },
  orderCard: {
    borderRadius: 0,
    borderWidth: 1,
    borderColor: '#504e4a33',
    backgroundColor: white,
    shadow: 'none',
    footerBackground: white,
    headerBackground: white,
    linksColor: '#9c8773ff',
    linksUnderline: true,
    ...getFontConfig('PT Sans', '#504e4aff')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 19,
    ...getFontConfig('PT Sans', '#504e4aff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 19,
    ...getFontConfig('Manrope', '#000000ff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 11,
    ...getFontConfig('PT Sans', '#504e4aff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 11,
    ...getFontConfig('PT Sans', '#504e4aff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: '#504e4a33',
    borderRadius: 0,
    borderWidth: 1,
    backgroundColor: '#ffffff',
    button: {
      on: true,
      backgroundColor: '#ffffff',
      borderColor: '#9c8773ff',
      borderRadius: 0,
      borderWidth: 1,
      shadow: 'none',
      ...getFontConfig('Nunito', '#9c8773ff')
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: white,
      borderColor: '#504e4a33',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('PT Sans', '#000000ff')
      },
      info: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('PT Sans', '#504e4aff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: '#9c8773ff',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('PT Serif', '#9c8773ff')
  },
  detailsButton: {
    on: null,
    backgroundColor: '#9c8773ff',
    borderColor: '#00ccff00',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('PT Serif', white)
  },
  trackButton: {
    on: null,
    backgroundColor: '#9c8773ff',
    borderColor: '#ee7227',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('PT Serif', white)
  },
  pageScroll: {
    scrollColor: '#00000033',
    borderRadius: 4,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/5c98ebd5cf8df_sparo-180x.png')),
  socialIcons: getSocialIcons('#9c8773ff'),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: white,
    borderColor: '#504e4a33',
    borderRadius: 0,
    borderWidth: 1,
    sidebarField: 'orderNumbers',
    shadow: 'none',
    ...getFontConfig('PT Sans', '#504e4aff')
  },
  footer: {
    linksColor: '#9c8773ff',
    linksUnderline: true,
    backgroundColor: white,
    ...getFontConfig('PT Sans', '#504E4A'),
    ...getFooterItems()
  },
  header: {
    backgroundColor: white
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#504e4a33',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#504e4a33',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#9c8773ff',
        borderColor: '#00ccff00',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('PT Serif', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('PT Sans', '#504e4aff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('PT Sans', '#504e4aff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('PT Sans', '#504e4aff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#9c8773ff',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#504e4a33',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#504e4a33',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#9c8773ff',
        borderColor: '#00ccff00',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('PT Serif', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('PT Sans', '#504e4aff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('PT Sans', '#504e4aff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('PT Sans', '#504e4aff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#9c8773ff',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
