import { Clipboard } from '@angular/cdk/clipboard';

export const copyTextToClipboard = (text) => {
  const clipboard = new Clipboard(document);
  try {
    const pending = clipboard.beginCopy(text);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        pending.destroy();
      }
    };
    attempt();
    return Promise.resolve();
  } catch (e) {
    console.log(e);
    return Promise.reject('Copy to clipboard not suported');
  }
};
