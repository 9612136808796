import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { Toast } from './toast.model';
import { ToastService } from './toast.service';
import { flyInRightAnimation, fadeOutAnimation } from '../animations';

@Component({
  selector: 'app-toasts-container',
  template: `
    <div class="toast-container">
      <div *ngFor="let toast of toasts$ | async">
        <app-toast
          [@flyInRightAnimation]
          [@fadeOutAnimation]
          (toastDismiss)="onToastDismiss($event)"
          [toast]="toast"
        >
        </app-toast>
      </div>
    </div>
  `,
  styleUrls: ['./toasts-container.component.scss'],
  animations: [flyInRightAnimation, fadeOutAnimation],
  encapsulation: ViewEncapsulation.None
})
export class ToastsContainerComponent {
  public toasts$: Observable<Array<Toast>>;

  constructor(private toastService: ToastService) {
    this.toasts$ = toastService.toasts;
  }

  onToastDismiss(toast: Toast) {
    this.toastService.removeToast(toast);
  }
}
