import { Pagination } from './store/pagination-state';
export const DEFAULT_ITEMS_PER_PAGE = '10';
export const DEFAULT_PAGE = '1';
export const ITEMS_PER_PAGE_KEY = 'itemsPerPage';
export const PAGE_KEY = 'page';

export const DEFAULT_PAGINATION_PARAMS = {
  [PAGE_KEY]: DEFAULT_PAGE,
  [ITEMS_PER_PAGE_KEY]: DEFAULT_ITEMS_PER_PAGE
};

export enum HydraBackendKeys {
  member = 'hydra:member',
  search = 'hydra:search',
  totalItems = 'hydra:totalItems',
  pagination = 'hydra:view',
  hydraId = '@id'
}

interface HydraPagination {
  'hydra:first': string;
  'hydra:last': string;
  'hydra:next': string;
  'hydra:previous': string;
}

export interface HydraCollectionResponse<T extends Object> {
  'hydra:member': T[];
  'hydra:search': any;
  'hydra:totalItems': number;
  'hydra:view'?: HydraPagination;
}

interface ItemsResponse<T> {
  items: T[];
  totalItems: number;
}

export const hydraCollectionToArray = <T>(
  response: HydraCollectionResponse<T>
): T[] => response[HydraBackendKeys.member];

export const hydraCollectionToItemsResponse = <T>(
  response: HydraCollectionResponse<T>
): ItemsResponse<T> => ({
  items: response[HydraBackendKeys.member],
  totalItems: response[HydraBackendKeys.totalItems]
});

const extractPageNumFromUrl = (url: string) => {
  const [page = ''] = url.match(/(page=\d*)/) || [];
  return page.split('=')[1];
};

const preparePaginationView = (view: HydraPagination): any =>
  Object.entries(view)
    .filter(([key, value]) => !key.includes('@'))
    .reduce<any>(
      (acc, [key, value]) => ({
        ...acc,
        [key.replace('hydra:', '')]: extractPageNumFromUrl(value)
      }),
      <any>{}
    );

const getCurrentPageFromPagination = ({ next, last = 1 }: any): string => {
  const current = next ? parseInt(next, 0) - 1 : last;
  return `${current}`;
};

const getPerPage = (url: string): string => {
  const [perPage = ''] = url.match(/(itemsPerPage=\d*)/) || [];
  const result = perPage.split('=')[1] || DEFAULT_ITEMS_PER_PAGE;
  return result.toString();
};

export function hydraCollectionToPaginationItems<T>(
  response: HydraCollectionResponse<T>
): Pagination<T> {
  const partial = preparePaginationView(response[HydraBackendKeys.pagination]);
  return {
    ...partial,
    items: response[HydraBackendKeys.member],
    totalItems: response[HydraBackendKeys.totalItems],
    current: getCurrentPageFromPagination(partial),
    perPage: getPerPage(
      response[HydraBackendKeys.pagination][HydraBackendKeys.hydraId]
    )
  };
}

export const createdFirstParams = {
  'order[createdAt]': 'desc'
};

export const updatedFirstParams = {
  'order[updatedAt]': 'desc'
};
