import { ActionWithPayload } from 'app/helpers';
import { Action } from '@ngrx/store';

export enum RouterTypes {
  GO = '[Router] Go',
  BACK = '[Router] Back'
}

export class Go implements ActionWithPayload<any> {
  readonly type = RouterTypes.GO;
  constructor(public payload: any) {}
}

export class Back implements Action {
  readonly type = RouterTypes.BACK;
}

export type RouterAction = Go | Back;
