import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastsContainerComponent } from './toasts-container.component';
import { ToastComponent } from './toast.component';
import { ToastService } from './toast.service';

@NgModule({
  imports: [CommonModule],
  declarations: [ToastsContainerComponent, ToastComponent],
  exports: [ToastsContainerComponent],
  providers: [ToastService]
})
export class ToastModule {
  public static forRoot(): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [ToastService]
    };
  }
}
