import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white
} from './helpers';

export const tech: TrackingPageModel = {
  languageMenu: getLanguageMenu('Metrophobic', '#191d28ff'),
  searchPageInput: {
    backgroundColor: '#ffffff',
    borderColor: '#ced4da',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Ubuntu', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 25,
    ...getFontConfig('Roboto Condensed', '#1a1e29ff', '700')
  },
  background: {
    color: '#ffffff',
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/tech.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: '#ffffff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Roboto Condensed', '#1a1e29ff', '700')
  },
  orderCard: {
    backgroundColor: '#f6f6f6ff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#f0f0f0ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 18,
    ...getFontConfig('Roboto Condensed', '#1d8af5ff', '700')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Metrophobic', '#1a1e29ff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Roboto Condensed', '#565656ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Roboto Mono', '#1d95f5ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    scroll: false,
    itemsLimit: 1,
    borderColor: '#f0f0f0ff',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: '#ffffffff',
    shadow: 'none',
    button: {
      on: true,
      backgroundColor: '#0080fdff',
      borderColor: '#2E4068',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      ...getFontConfig('Roboto Condensed', white, '700')
    },
    itemCard: {
      backgroundColor: '#ffffffff',
      borderColor: '#2E4068',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 16,
        ...getFontConfig('Roboto Condensed', '#1a1e29ff', '700')
      },
      info: {
        alignment: 'left',
        fontSize: 14,
        ...getFontConfig('Roboto Condensed', '#727272ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#0080fdff',
    borderColor: '#2e406800',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Metrophobic', '#ffffffff')
  },
  detailsButton: {
    backgroundColor: '#1d84f5ff',
    borderColor: '#5780ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Roboto Condensed', white, '700')
  },
  trackButton: {
    backgroundColor: '#f5951dff',
    borderColor: '#ee7227',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Roboto Condensed', white, '700')
  },
  pageScroll: {
    scrollColor: '#2E4068',
    borderRadius: 2,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/tech-logo.png')),
  socialIcons: getSocialIcons('#b4b4b4ff'),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#ffffff',
    borderColor: '#CED2DC',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    sidebarField: null,
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  footer: {
    backgroundColor: '#ffffffff',
    linksColor: '#1da0f5ff',
    linksUnderline: false,
    ...getFooterItems(),
    ...getFontConfig('Inter', '#565656ff')
  },
  header: {
    backgroundColor: '#ffffff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: false,
    backgroundColor: '#f6f6f6ff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: 0,
      borderWidth: 5,
      shadow: 'none',
      button: {
        backgroundColor: '#1d8af5ff',
        borderColor: '#5780ff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Roboto Condensed', white, '700')
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Metrophobic', '#1a1e29ff')
      },
      regularPrice: {
        alignment: 'left',
        fontSize: 10,
        ...getFontConfig('Roboto Condensed', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Roboto Condensed', '#000000ff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('tech'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#f5951dff',
      arrowSize: 35
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#f6f6f6ff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: 0,
      borderWidth: 5,
      shadow: 'none',
      button: {
        backgroundColor: '#1d8af5ff',
        borderColor: '#5780ff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Roboto Condensed', white, '700')
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Metrophobic', '#1a1e29ff')
      },
      regularPrice: {
        alignment: 'left',
        fontSize: 10,
        ...getFontConfig('Roboto Condensed', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Roboto Condensed', '#000000ff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('tech'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#f5951dff',
      arrowSize: 35
    }
  },
  seoTitle: ''
};
