import { TrackingPageModel } from '../tracking-page.model';
import {
  getFontConfig,
  getAsset,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white,
  getProductItems
} from './helpers';

export const fairyCake: TrackingPageModel = {
  languageMenu: getLanguageMenu('Nunito', white),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('Nunito', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Nunito', '#ffffffff')
  },
  background: {
    color: white,
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/5cb809e0743ff_background.jpg'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: white,
    headerBackground: null,
    footerBackground: null,
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    linksColor: '#058677',
    linksUnderline: true,
    ...getFontConfig('Nunito', '#565656ff')
  },
  orderCard: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#d5d9e1',
    backgroundColor: white,
    shadow: 'none',
    footerBackground: null,
    headerBackground: null,
    linksColor: '#058677',
    linksUnderline: true,
    ...getFontConfig('Nunito', '#565656ff')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Nunito', '#565656ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 21,
    ...getFontConfig('Nunito', '#c52cc0ff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 14,
    ...getFontConfig('Nunito', '#cfcfcfff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 14,
    ...getFontConfig('Nunito', '#565656ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: '#cd90d0ff',
    borderRadius: 23,
    borderWidth: 1,
    backgroundColor: '#ffffff',
    button: {
      on: true,
      backgroundColor: '#d66bcdff',
      borderColor: '#00000000',
      borderRadius: 22,
      borderWidth: 1,
      shadow: 'none',
      ...getFontConfig('Nunito', '#ffffffff')
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: white,
      borderColor: white,
      borderRadius: 0,
      borderWidth: 1,
      shadow: 'none',
      title: {
        alignment: 'center',
        fontSize: 17,
        ...getFontConfig('Nunito', '#000000ff')
      },
      info: {
        alignment: 'center',
        fontSize: 14,
        ...getFontConfig('Nunito', '#565656ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: white,
    borderRadius: 22,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Nunito', white)
  },
  detailsButton: {
    on: null,
    backgroundColor: '#7735b8ff',
    borderColor: '#00ccff00',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Nunito', white)
  },
  trackButton: {
    on: null,
    backgroundColor: '#7735b8ff',
    borderColor: '#ee7227',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Nunito', white)
  },
  pageScroll: {
    scrollColor: '#ffffff75',
    borderRadius: 4,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(
    getAsset('/images/themes/5cb80f4806a65_logo-test-white-260x-2x-1.png')
  ),
  socialIcons: getSocialIcons(white),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: white,
    borderColor: white,
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    sidebarField: 'orderNumbers',
    ...getFontConfig('Nunito', '#565656ff')
  },
  footer: {
    linksColor: white,
    linksUnderline: true,
    backgroundColor: '#00ccff00',
    ...getFontConfig('Nunito', white),
    ...getFooterItems()
  },
  header: {
    backgroundColor: '#00ccff00'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#ffffffff',
      borderRadius: 5,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#eb4ed9ff',
        borderColor: '#00ccff00',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Nunito', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('Nunito', '#565656ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Nunito', '#FA0000ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 20,
        ...getFontConfig('Inter', '#29a03fff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#ffffffff',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: '#d5d9e100',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: white,
      borderRadius: 5,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#ef48beff',
        borderColor: '#00ccff00',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Nunito', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('Nunito', '#565656ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Give You Glory', '#767676ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 20,
        ...getFontConfig('Inter', '#1e852cff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#ffffffff',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
