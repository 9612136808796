import { Injectable } from '@angular/core';
import { BehaviorSubject, of, fromEvent, merge } from 'rxjs';
import { mapTo, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { ToastService } from './toast';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class IsOnlineService {
  private _isOnline$ = new BehaviorSubject(true);
  isOnline: boolean;

  constructor(private toastService: ToastService) {}

  isOnlineRequest() {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      const url = environment.appUrl;

      xhr.open('HEAD', url, true);
      xhr.send();

      xhr.addEventListener(
        'readystatechange',
        () => {
          if (xhr.readyState !== 4) {
            return;
          }

          if (xhr.status >= 200 && xhr.status < 304) {
            return resolve(true);
          }

          return resolve(false);
        },
        false
      );
    });
  }

  startOnlineCheck() {
    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    )
      .pipe(
        tap(value => {
          this.isOnline = value;
        }),
        distinctUntilChanged(),
        skip(1)
      )
      .subscribe(value => {
        this._isOnline$.next(value);
        !value
          ? this.toastService.triggerWarningToast(
              this.toastService.generateToastMessage(
                'Warning',
                'You are currently offline or having internet connection problem. Please, check your connection'
              )
            )
          : this.toastService.triggerInfoToast(
              this.toastService.generateToastMessage(
                'Info',
                'You are currently online'
              )
            );
      });
  }

  get isOnline$() {
    return this._isOnline$.asObservable();
  }
}
