import { TrackingPageModel } from '../tracking-page.model';
import {
  getAsset,
  getAppImage,
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white,
  getProductItems
} from './helpers';

export const snowdevil: TrackingPageModel = {
  languageMenu: getLanguageMenu('Karla', white),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('Karla', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Karla', '#666666ff')
  },
  background: {
    color: '#f7f7f7ff',
    useBackgroundImage: false,
    imageUrl: getAppImage('/assets/img/tracking-page/Img_map_white.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    linksColor: '#006effff',
    linksUnderline: false,
    backgroundColor: white,
    headerBackground: white,
    footerBackground: white,
    borderColor: '#66666633',
    borderRadius: 3,
    borderWidth: 1,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Karla', '#666666ff')
  },
  orderCard: {
    borderRadius: 0,
    borderWidth: 1,
    borderColor: '#66666633',
    backgroundColor: white,
    shadow: 'none',
    footerBackground: white,
    headerBackground: white,
    linksColor: '#006effff',
    linksUnderline: false,
    ...getFontConfig('Karla', '#666666ff')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 19,
    ...getFontConfig('Oswald', '#2617efff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 23,
    ...getFontConfig('Oswald', '#000000ff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 14,
    ...getFontConfig('Karla', '#666666ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 16,
    ...getFontConfig('Karla', '#bc1c1cff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    itemsLimit: 4,
    scroll: false,
    on: true,
    shadow: 'none',
    borderColor: '#66666633',
    borderRadius: 0,
    borderWidth: 1,
    backgroundColor: '#ffffff',
    button: {
      on: true,
      backgroundColor: '#ffffff',
      borderColor: white,
      borderRadius: 3,
      borderWidth: 2,
      shadow: 'none',
      ...getFontConfig('Oswald', '#271cc5ff')
    },
    itemCard: {
      backgroundColor: white,
      borderColor: '#66666633',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Oswald', '#000000ff')
      },
      info: {
        alignment: 'left',
        fontSize: 15,
        ...getFontConfig('Karla', '#666666ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: white,
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Oswald', white)
  },
  detailsButton: {
    on: null,
    backgroundColor: '#006effff',
    borderColor: '#00ccff00',
    borderRadius: 3,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Montserrat', white)
  },
  trackButton: {
    on: null,
    backgroundColor: '#006effff',
    borderColor: '#ee7227',
    borderRadius: 3,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Montserrat', white)
  },
  pageScroll: {
    scrollColor: '#00000033',
    borderRadius: 4,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/5c98dc959adff_logo-3-165x-2x.png')),
  socialIcons: getSocialIcons(white),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: white,
    borderColor: '#66666633',
    borderRadius: 0,
    borderWidth: 1,
    sidebarField: 'orderNumbers',
    shadow: 'none',
    ...getFontConfig('Oswald', '#000000ff')
  },
  footer: {
    linksColor: '#006effff',
    linksUnderline: false,
    backgroundColor: '#f7f7f7ff',
    ...getFontConfig('Karla', '#666666ff'),
    ...getFooterItems()
  },
  header: {
    backgroundColor: '#030303ff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  defaultThemeId: 'snowdevil',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#66666633',
    borderRadius: 3,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#66666633',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        on: null,
        backgroundColor: '#1b25cdff',
        borderColor: '#00ccff00',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Oswald', white)
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'center',
        fontSize: 20,
        ...getFontConfig('Oswald', '#000000ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Karla', '#666666ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Oswald', '#2718b0ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#000000ff',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#66666633',
    borderRadius: 3,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#66666633',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#2a27dbff',
        borderColor: '#2a27b700',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Oswald', white)
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'center',
        fontSize: 18,
        ...getFontConfig('Oswald', '#000000ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Karla', '#666666ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Roboto Condensed', '#201aa9ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#000000ff',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
