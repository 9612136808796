import { TrackingPageModel } from '../tracking-page.model';
import {
  getAppImage,
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white,
  getProductItems
} from './helpers';

export const defaultTheme: TrackingPageModel = {
  languageMenu: getLanguageMenu('Ubuntu', '#324065ff'),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('Ubuntu', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  background: {
    color: white,
    useBackgroundImage: true,
    imageUrl: getAppImage('/assets/img/tracking-page/Img_map_white.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  trackButton: {
    on: null,
    backgroundColor: '#ee7227',
    borderColor: '#ee7227',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Ubuntu', white)
  },
  card: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#ffffffff',
    backgroundColor: white,
    shadow: 'none',
    footerBackground: white,
    headerBackground: white,
    linksColor: '#5780ff',
    linksUnderline: false,
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  orderCard: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#f2f2f2ff',
    backgroundColor: white,
    shadow: 'none',
    footerBackground: white,
    headerBackground: white,
    linksColor: '#5780ff',
    linksUnderline: false,
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Montserrat', '#324065ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Ubuntu', '#324065ff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Montserrat', '#6f788aff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Ubuntu', '#de7415ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: null,
    borderColor: '#607ff7ff',
    borderRadius: 16,
    borderWidth: 1,
    backgroundColor: '#ffffff',
    button: {
      on: true,
      backgroundColor: '#ffffff',
      borderColor: '#607ff7ff',
      borderRadius: 22,
      borderWidth: 1,
      shadow: 'none',
      ...getFontConfig('Ubuntu', '#607ff7ff')
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: '#ffffff',
      borderColor: white,
      borderRadius: 16,
      borderWidth: 1,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Ubuntu', '#324065ff')
      },
      info: {
        alignment: 'left',
        fontSize: 13,
        ...getFontConfig('Ubuntu', '#324065ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#324065ff',
    borderColor: '#2E4068',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Ubuntu', '#ffffffff')
  },
  detailsButton: {
    on: null,
    backgroundColor: '#5780ff',
    borderColor: '#5780ff',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Ubuntu', white)
  },
  pageScroll: {
    scrollColor: '#2E4068',
    borderRadius: 2,
    backgroundColor: '#ffffff00'
  },
  logo: getLogo(getAppImage('/assets/themes/default-logo.png')),
  socialIcons: getSocialIcons('#2E4068'),
  sidebar: {
    borderColor: '#f2f2f2ff',
    backgroundColor: white,
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    shadow: 'none',
    borderRadius: 4,
    borderWidth: 1,
    sidebarField: 'orderNumbers',
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  footer: {
    linksColor: '#5780ff',
    linksUnderline: false,
    backgroundColor: white,
    ...getFooterItems(),
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  header: {
    backgroundColor: '#ffffffff'
  },
  scripts: {
    head: null,
    body: null
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#f2f2f2ff',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: white,
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        on: null,
        backgroundColor: '#5780ff',
        borderColor: '#5780ff',
        borderRadius: 4,
        borderWidth: 1,
        shadow: 'none',
        ...getFontConfig('Ubuntu', white)
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Ubuntu', '#324065ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Ubuntu', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 20,
        ...getFontConfig('Ubuntu', '#34b21eff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#324065ff',
      arrowSize: 35
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#ffffffff',
    borderColor: '#f2f2f2ff',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: white,
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        on: null,
        backgroundColor: '#5780ff',
        borderColor: '#5780ff',
        borderRadius: 4,
        borderWidth: 1,
        shadow: 'none',
        ...getFontConfig('Ubuntu', white)
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Ubuntu', '#324065ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Ubuntu', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 20,
        ...getFontConfig('Ubuntu', '#379241ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#324065ff',
      arrowSize: 35
    }
  },
  seoTitle: ''
};
