import { ActionWithPayload } from './store';
import { pickBy, isArray, isPlainObject, omit, isEmpty } from 'lodash';
import { cond, T, always } from 'ramda';

export interface StateWithSortAndFilter {
  sort?: {
    [key: string]: string;
  };
  filter: {
    [key: string]: any;
  };
}

export interface FilterPayload {
  code: string;
  data: any;
}

const animation = 250;
const handle = '.dragndrop';

const getDataIds = (elements) =>
  Array.prototype.slice
    .call(elements.children)
    .map((child: HTMLElement) => child.getAttribute('data-js-id'));

const defaultCallback = (data) => console.log('NEW SORTING IDS ->', data);

export const getSortingOptions = (callback = defaultCallback) => ({
  onEnd: (event) => {
    callback(getDataIds(event.to));
  },
  handle,
  animation
});

const SORT_ORDER_KEY = 'order[';

const keyPassesRegex = (regex: RegExp) => (value: string) => regex.test(value);

/**
 * Regex to tests if query key is of type array by defining string[number] in key
 *
 * @example
 *
 * status[0] - returns true
 * createdAt[before] - returns false
 */
const FILTER_ARRAY_REGEX = /(\[[0-9]\])/g;
/**
 * Regex to tests if query key is of type nested object by defining string[string] in key
 *
 * @example
 *
 * status[0] - returns false
 * createdAt[before] - returns true
 */
const FILTER_OBJECT_REGEX = /(\[)+([a-zA-Z])+(\])/g;

/**
 * Regex to remove order[] part of the string
 *
 * @example
 *
 * order[status] to return status
 */
const ORDER_KEY_REGEX = /^(order\[)|(\])$/gi;

/**
 * Regex to tests if string has brackets around like [test]
 *
 * @example
 */
const BRACKETS_AROUND_VALUE_REGEX = /^(\[)|(\])$/g;

/**
 * Regex to remove empty[] part of the string
 *
 * @example
 *
 * empty[shipments] to return shipments
 */
const EMPTY_KEY_REGEX = /^(empty\[)|(\])$/gi;

const getFilterValueObject = (acc, [key, value]) =>
  cond([
    [
      keyPassesRegex(FILTER_ARRAY_REGEX),
      () => {
        const newKey = key.replace(FILTER_ARRAY_REGEX, '');
        return {
          ...acc,
          [newKey]: acc[newKey] ? [...acc[newKey], value] : [value]
        };
      }
    ],
    [
      keyPassesRegex(FILTER_OBJECT_REGEX),
      () => {
        const newKey = key.replace(FILTER_OBJECT_REGEX, '');
        const subKey = key
          .replace(newKey, '')
          .replace(BRACKETS_AROUND_VALUE_REGEX, '');
        const valueObject = acc[newKey]
          ? {
              ...acc[newKey],
              [subKey]: value
            }
          : {
              [subKey]: value
            };
        return {
          ...acc,
          [newKey]: valueObject
        };
      }
    ],
    [
      T,
      () => ({
        ...acc,
        [key]: value
      })
    ]
  ])(key);

export const serializeFilterObject = (object) =>
  Object.entries(
    pickBy(object, (value, key) => !key.includes(SORT_ORDER_KEY))
  ).reduce(getFilterValueObject, {});

export const serializeSortObject = (object) =>
  Object.entries(
    pickBy(object, (value, key) => key.includes(SORT_ORDER_KEY))
  ).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.replace(ORDER_KEY_REGEX, '')]: value
    }),
    {}
  );

export const setFilteringParams = (filter = {}) =>
  Object.entries(filter).reduce((acc, [key, value]: [any, any]) => {
    const result = cond([
      [
        isArray,
        () =>
          value.reduce(
            (accum, curr, i) => ({
              ...accum,
              [`${key}[${i}]`]: curr
            }),
            {}
          )
      ],
      [
        isPlainObject,
        always({
          ...Object.entries(value).reduce(
            (accumulator, [subKey, subValue]) => ({
              ...accumulator,
              [`${key}[${subKey}]`]: subValue
            }),
            {}
          )
        })
      ],
      [T, always({ [key]: value })]
    ])(value);
    return {
      ...acc,
      ...result
    };
  }, {});

export const setSortParams = (sort = {}) => {
  const [[key, value] = [null, null]] = Object.entries(sort);
  return key
    ? {
        [`${SORT_ORDER_KEY}${key}]`]: value
      }
    : {};
};

export const toggleItemFromArray = (array, item) => {
  const itemsArray = isArray(item) ? item : [item];
  return [...array, ...itemsArray].filter(
    (combinedItem) =>
      !array.includes(combinedItem) || !itemsArray.includes(combinedItem)
  );
};

export const modifyArrayFilter = (currentFilter = [], nextFilter) =>
  toggleItemFromArray(currentFilter, nextFilter);

export const toggleFiltering =
  <S extends StateWithSortAndFilter>() =>
  (
    state: S,
    { payload: { code, data } }: ActionWithPayload<FilterPayload>
  ): S & StateWithSortAndFilter => {
    let filter = data
      ? {
          ...state.filter,
          [code]: isArray(data)
            ? modifyArrayFilter(state.filter[code], data)
            : data
        }
      : omit(state.filter, code);
    filter = isEmpty(filter[code]) ? omit(filter, code) : filter;
    return {
      ...state,
      filter
    };
  };

export const modifyQueryParams = (queryParams) => {
  Object.keys(queryParams).forEach((key) => {
    if (key.includes('empty[')) {
      queryParams[key.replace(EMPTY_KEY_REGEX, '')] = 'ONLY_EMPTY';
      delete queryParams[key];
    }
  });
  return queryParams;
};
