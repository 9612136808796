import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white
} from './helpers';

export const yourstyle: TrackingPageModel = {
  languageMenu: getLanguageMenu('Montserrat', '#393939ff'),
  searchPageInput: {
    backgroundColor: '#ffffff',
    borderColor: '#eeeeeeff',
    borderRadius: 21,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Inter', '#dadadaff', '700')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Inter', '#393939ff', '700')
  },
  background: {
    color: white,
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/yourstyle.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: '#f7f7f7ff',
    linksColor: '#ef8d46ff',
    linksUnderline: false,
    headerBackground: '#f7f7f7ff',
    footerBackground: '#f7f7f7ff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Inter', '#2c2c2cff')
  },
  orderCard: {
    backgroundColor: '#ffffff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Inter', '#303030ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Inter', '#393939ff', '800')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 13,
    ...getFontConfig('Inter', '#9c9c9cff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 16,
    ...getFontConfig('Inter', '#e56666ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    scroll: false,
    itemsLimit: 2,
    borderColor: '#eeeeeeff',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: '#ffffffff',
    shadow: 'none',
    button: {
      on: true,
      backgroundColor: '#393939ff',
      borderColor: '#393939ff',
      borderRadius: 22,
      borderWidth: 1,
      shadow: 'none',
      ...getFontConfig('Inter', white)
    },
    itemCard: {
      backgroundColor: '#ffffffff',
      borderColor: '#2E4068',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 17,
        ...getFontConfig('Inter', '#393939ff', '700')
      },
      info: {
        alignment: 'left',
        fontSize: 13,
        ...getFontConfig('Inter', '#393939ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: '#cfd2dbff',
    borderRadius: 22,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Montserrat', '#393939ff', '700')
  },
  detailsButton: {
    backgroundColor: '#393939ff',
    borderColor: '#5780ff',
    borderRadius: 22,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Ubuntu', white)
  },
  trackButton: {
    backgroundColor: '#393939ff',
    borderColor: '#393939ff',
    borderRadius: 21,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Inter', white, '700')
  },
  pageScroll: {
    scrollColor: '#2E4068',
    borderRadius: 2,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/yourstyle-logo.png')),
  socialIcons: getSocialIcons('#393939ff', false),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#ffffff',
    borderColor: '#CED2DC',
    borderRadius: 2,
    borderWidth: 0,
    shadow: 'none',
    sidebarField: null,
    ...getFontConfig('Inter', '#171717ff')
  },
  footer: {
    linksColor: '#ffffffff',
    linksUnderline: false,
    backgroundColor: '#2d2e33ff',
    ...getFooterItems(),
    ...getFontConfig('Inter', '#ffffffff')
  },
  header: {
    backgroundColor: '#ffffff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: false,
    backgroundColor: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffffff',
      borderColor: '#ffffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#393939ff',
        borderColor: '#5780ff',
        borderRadius: 22,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Inter', white, '700')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Inter', '#393939ff', '900')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Ubuntu', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 14,
        ...getFontConfig('Inter', '#393939ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('yourstyle'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#535353ff',
      arrowSize: 30
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffffff',
      borderColor: '#ffffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#393939ff',
        borderColor: '#5780ff',
        borderRadius: 22,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Inter', white, '700')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Inter', '#393939ff', '900')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Ubuntu', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 14,
        ...getFontConfig('Inter', '#393939ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('yourstyle'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#535353ff',
      arrowSize: 30
    }
  },
  seoTitle: ''
};
