import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class AppGuard implements CanLoad {
  constructor(private router: Router) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (
      environment.production &&
      environment.appUrl !== window.location.origin
    ) {
      this.router.navigate(['/tracking-page/search']);
      return false;
    }
    return true;
  }
}
