import {
  TrackingPageFont,
  TrackingPageLanguageMenu,
  TrackingPageLogo,
  TrackingPageProductsFeedItem,
  TrackingPageSocialIcons
} from '../tracking-page.model';
import { environment } from '../../../../environments/environment';

export const getFontConfig = (
  fontFamily: string,
  fontColor: string,
  fontStyle: string = 'regular'
): TrackingPageFont => ({
  font: {
    fontFamily,
    fontColor,
    fontStyle,
    customFontUrl: null,
    customFontName: null,
    customFontUsed: false
  }
});

const mainProductsFeedItems = [
  {
    title: 'Blue T-Shirt',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/blue-tshirt.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Red T-Shirt',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/red-tshirt.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Green T-Shirt',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/green-tshirt.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'White T-Shirt',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/white-tshirt.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Black T-Shirt',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/black-tshirt.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  }
];
const luxuryBrandsSliderItems = [
  {
    title: 'Matte Beauty Powder',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/luxury-cosmetics-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Matte Beauty Powder',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/luxury-cosmetics-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Gucci Matte Primer',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/luxury-cosmetics-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Gucci Matte Primer',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/luxury-cosmetics-4.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  }
];
const bakerHouseSliderItems = [
  {
    title: 'Baking dish',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/baker-house-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Baking dish',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/baker-house-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Baking dish',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/baker-house-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Baking dish',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/baker-house-4.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Baking dish',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/baker-house-5.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  }
];
const dogsLoveSliderItems = [
  {
    title: 'Royal Canin Ultamind...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/dogs-love-3.png`,
    regularPrice: '$120.23',
    discountedPrice: '$78.99'
  },
  {
    title: 'Goodies Dog Treats...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/dogs-love-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Dental Energy dog go...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/dogs-love-4.png`,
    regularPrice: '$25.00',
    discountedPrice: '$10.99'
  },
  {
    title: 'Himalaya Adult Dry D...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/dogs-love-1.png`,
    regularPrice: '$87.00',
    discountedPrice: '$60.29'
  },
  {
    title: 'Royal Canin Ultamind...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/dogs-love-3.png`,
    regularPrice: '$120.23',
    discountedPrice: '$78.99'
  }
];
const foodSliderItems = [
  {
    title: 'California Gold Nutr...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/food-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Jamieson Vitamin C',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/food-4.png`,
    regularPrice: '$14.99',
    discountedPrice: '$10.99'
  },
  {
    title: 'Complex SW Vitamin C',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/food-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Natures AID Vitamin ...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/food-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'California Gold Nutr...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/food-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  }
];
const seoulSliderItems = [
  {
    title: 'Blooming lipstick',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/seoul-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Dark Circle consealer',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/seoul-5.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Delight tint',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/seoul-4.png`,
    regularPrice: '$9',
    discountedPrice: '$5'
  },
  {
    title: 'Dark circle consealer',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/seoul-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Face lighting & shading',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/seoul-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  }
];
const toyStoreSliderItems = [
  {
    title: 'Playskool Sit ‘n Spi...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/toy-store-4.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'CoComelon Nursery...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/toy-store-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'CoComelon Deluxe Fam...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/toy-store-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Sunny Days Entertain...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/toy-store-3.png`,
    regularPrice: '$65',
    discountedPrice: '$54'
  }
];
const yourStyleSliderItems = [
  {
    title: 'Nike Air Solid Red',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/yourstyle-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Nike Air Jordan 1 Cl...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/yourstyle-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Nike Air Red',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/yourstyle-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Nike Air Max Women',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/yourstyle-4.png`,
    regularPrice: '$65',
    discountedPrice: '$54'
  }
];
const fitnessStoreSliderItems = [
  {
    title: 'Body Power SP.IC20 I...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/fitness-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'NordicTrack E8.2 Ell...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/fitness-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'ProForm Trainer 8.5 ...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/fitness-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  }
];
const artStoreSliderItems = [
  {
    title: 'Pablo Tshirt, Pablo ...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/art-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Pablo Tshirt, Pablo ...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/art-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Pablo Tshirt, Pablo ...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/art-4.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Van Gogh One Line / ...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/art-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  }
];
const tourismSliderItems = [
  {
    title: 'Farm Rio Full of Flowers Midi Sundress ',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/tourism-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Lunch Hour Canvas Co...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/tourism-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Finishline Trucker Hat',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/tourism-4.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Highlite Scallop 19...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/tourism-2.png`,
    regularPrice: '$65',
    discountedPrice: '$54'
  }
];
const furnitureSliderItems = [
  {
    title: 'BERGIG bookcase',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/furniture-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'BERGIG bookcase',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/furniture-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'BERGIG bookcase',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/furniture-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'BERGIG bookcase',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/furniture-4.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  }
];
const carPartsSliderItems = [
  {
    title: 'Duralast Loaded Stru...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/car-parts-1.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Duralast Gold Sway B...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/car-parts-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'Duralast Brake Rotor...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/car-parts-2.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  },
  {
    title: 'PowerStop Performanc...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/car-parts-4.png`,
    regularPrice: '$25.00',
    discountedPrice: '$19.99'
  }
];
const techSliderItems = [
  {
    title: 'Fitbit Versa 2 Healt...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/tech-1.png`,
    regularPrice: '$250',
    discountedPrice: '$149'
  },
  {
    title: 'Sennheiser Momentum ...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/tech-2.png`,
    regularPrice: '$1200',
    discountedPrice: '$975'
  },
  {
    title: 'Fitbit Versa 2 Healt...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/tech-3.png`,
    regularPrice: '$250',
    discountedPrice: '$149'
  }
];

const kitchenwareSliderItems = [
  {
    title: 'Wrendale Lovely Mum ...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/kitchenware-3.png`,
    regularPrice: '$25.00',
    discountedPrice: '$17.80'
  },
  {
    title: 'Sistema Bento Lunch ...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/kitchenware-4.png`,
    regularPrice: '$25.00',
    discountedPrice: '$10.80'
  },
  {
    title: 'Zone Beige Hexagon T...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/kitchenware-1.png`,
    regularPrice: '$15.00',
    discountedPrice: '$8.90'
  },
  {
    title: 'Premier Housewares C...',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/kitchenware-2.png`,
    regularPrice: '$10.00',
    discountedPrice: '$8.90'
  }
];

const sidebarProductsFeedItems = [
  {
    title: 'Red Cap',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/red-cap.png`,
    regularPrice: '$15.00',
    discountedPrice: '$9.99'
  },
  {
    title: 'White Cap',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/white-cap.png`,
    regularPrice: '$15.00',
    discountedPrice: '$9.99'
  },
  {
    title: 'Black Cap',
    buttonUrl: 'https://trackmage.com',
    imageUrl: `${environment.appUrl}/assets/img/products/black-cap.png`,
    regularPrice: '$15.00',
    discountedPrice: '$9.99'
  }
];

export const getProductItems = (
  type: string
): TrackingPageProductsFeedItem[] => {
  switch (type) {
    case 'sidebar':
      return sidebarProductsFeedItems;
    case 'main':
      return mainProductsFeedItems;
    case 'luxury-brand':
      return luxuryBrandsSliderItems;
    case 'baker-house':
      return bakerHouseSliderItems;
    case 'dogs-love':
      return dogsLoveSliderItems;
    case 'food':
      return foodSliderItems;
    case 'seoul':
      return seoulSliderItems;
    case 'toy-store':
      return toyStoreSliderItems;
    case 'yourstyle':
      return yourStyleSliderItems;
    case 'fitnes-store':
      return fitnessStoreSliderItems;
    case 'art-store':
      return artStoreSliderItems;
    case 'tourism':
      return tourismSliderItems;
    case 'furniture':
      return furnitureSliderItems;
    case 'car-parts':
      return carPartsSliderItems;
    case 'kitchenware':
      return kitchenwareSliderItems;
    case 'tech':
      return techSliderItems;
    default:
      return [];
  }
};

export const getSocialIcons = (
  fontColor: string,
  square: boolean = true
): TrackingPageSocialIcons => ({
  fontColor,
  icons: square
    ? [
        {
          url: '',
          icon: 'fa-square-x-twitter'
        },
        {
          url: '',
          icon: 'fa-square-facebook'
        }
      ]
    : [
        {
          url: '',
          icon: 'fa-x-twitter'
        },
        {
          url: '',
          icon: 'fa-facebook-f'
        }
      ],
  alignment: 'left',
  margin: {
    bottom: 0,
    left: 5,
    right: 5,
    top: 0
  }
});

export const getFooterItems = () => ({
  hideTrackmageBranding: false,
  links: [
    {
      title: 'Delivery Policy',
      url: ''
    },
    {
      title: 'Privacy Policy',
      url: ''
    },
    {
      title: 'Terms and Conditions',
      url: ''
    }
  ]
});

export const getLanguageMenu = (
  fontFamily = 'Ubuntu',
  fontColor = '#848484',
  fontStyle: string = 'regular'
): TrackingPageLanguageMenu => ({
  on: true,
  defaultLan: null,
  ...getFontConfig(fontFamily, fontColor, fontStyle)
});

export const getAppImage = (imageUrl: string): string =>
  `${environment.appUrl}${imageUrl}`;

export const getImage = (imageUrl: string) =>
  `${environment.imgUrl}${imageUrl}`;

export const getAsset = (assetUrl: string): string =>
  `${environment.assetsUrl}${assetUrl}`;

export const getLogo = (imageUrl: string): TrackingPageLogo => ({
  on: true,
  imageUrl,
  linkUrl: ''
});

export const white = '#ffffff';
