import {
  addDays,
  startOfMonth,
  subMonths,
  endOfMonth,
  startOfYear,
  subYears,
  subDays,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  parseISO,
  isValid,
  format
} from 'date-fns';

import { PresetType } from '../admin/shared/ngx-bootstrap-datepicker/datepicker/utils/date-presets';

export const RANGE_PAST_PRESETS = [
  {
    title: 'Last 7 days',
    id: PresetType.LAST_SEVEN_DAYS
  },
  {
    title: 'This month',
    id: PresetType.THIS_MONTH
  },
  {
    title: 'This Quarter',
    id: PresetType.THIS_QUARTER
  },
  {
    title: 'This Year',
    id: PresetType.THIS_YEAR
  },
  {
    title: 'Last Month',
    id: PresetType.LAST_MONTH
  },
  {
    title: 'Last Quarter',
    id: PresetType.LAST_QUARTER
  },
  {
    title: 'Last Year',
    id: PresetType.LAST_YEAR
  }
];

export function getFormattedDate(dateTime: string): string {
  const date = parseISO(dateTime);

  return isValid(date) ? format(date, 'd MMM yyyy') : 'None';
}

export function getDateByPreset(presetType: PresetType) {
  const now = new Date();
  switch (presetType) {
    case PresetType.LAST_SEVEN_DAYS: {
      const start = subDays(now, 7);
      const end = now;
      return [start, end];
    }
    case PresetType.THIS_MONTH: {
      return [startOfMonth(now), now];
    }
    case PresetType.THIS_YEAR: {
      return [startOfYear(now), now];
    }
    case PresetType.THIS_QUARTER: {
      return [startOfQuarter(now), endOfQuarter(now)];
    }
    case PresetType.LAST_MONTH: {
      const prevMonth = subMonths(startOfMonth(now), 1);
      return [startOfMonth(prevMonth), endOfMonth(prevMonth)];
    }
    case PresetType.LAST_QUARTER: {
      const start = startOfQuarter(subQuarters(now, 1));
      const end = endOfQuarter(start);
      return [start, end];
    }
    case PresetType.LAST_YEAR: {
      const start = addDays(startOfYear(now), 1);
      return [subYears(start, 1), start];
    }
  }
}
