import { pluck, map, concatMap } from 'rxjs/operators';
import { Action, props, ActionCreator, createAction } from '@ngrx/store';
import { Observable, of, OperatorFunction } from 'rxjs';
import { equals } from 'ramda';
import { EntityState, EntityAdapter } from '@ngrx/entity';
import { PaginationState } from './pagination-state';
import { Pagination } from '../../admin/shared/tables/pagination.model';
import { StateWithStatus, toggleStatusSuccess } from './state-with-status';

type ActionCreatorWithPayload<T> = ActionCreator<
  string,
  (props: { payload: T }) => { payload: T } & Action
>;

export const actionWithPayload = <T>(
  actionCreator: ActionCreatorWithPayload<T>,
  payload: T
) => actionCreator({ payload });

export const createActionWithPayload = <T = any>(actionType: string) =>
  createAction(actionType, props<{ payload: T }>());

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export type PayloadKey = 'payload';
export const PAYLOAD_KEY: PayloadKey = 'payload';

type extract<T> = T extends ActionWithPayload<infer U> ? U : never;

export const toPayload = <A>(): OperatorFunction<A, extract<A>> =>
  pluck<A, extract<A>>(PAYLOAD_KEY);

export interface ReducerLookUp<T, A> {
  [key: string]: (state: T, action: A) => T;
}

export function lookUpReducer<T, A extends ActionWithPayload<any>>(
  lookUp: ReducerLookUp<T, A>,
  state: T,
  action: A
): T {
  return lookUp[action.type] ? lookUp[action.type](state, action) : state;
}

export type ErrorActionCreator = (payload) => ActionWithPayload<any>;

const defaultErrorAction = (payload): ActionWithPayload<any> => {
  console.log('ERROR ->', payload);
  return { type: 'ERROR', payload };
};

export const errorActionHandler = (
  actionCreator: ErrorActionCreator = defaultErrorAction
) => (error): Observable<ActionWithPayload<any>> => of(actionCreator(error));

export const updateStateKeyWithPayload = <
  S extends Object,
  A extends ActionWithPayload<any>
>(
  key: keyof S
) => (state: S, action: A): S => {
  return {
    ...state,
    [key]: action.payload
  };
};

export const updateState = <
  S extends Object,
  A extends ActionWithPayload<any>
>(update: {
  [key: string]: any;
}) => (state: S, action: A): S =>
  Object.entries(update).reduce(
    (acc: any, [key, value]) => ({
      ...acc,
      [key]: equals(value, PAYLOAD_KEY) ? action.payload : value
    }),
    state
  );

export const addEntityAsFirst = <
  S extends EntityState<T>,
  T extends { id: string | number }
>(
  state: S,
  payload: T
): S => ({
  ...(state as any),
  ids: [payload.id, ...state.ids],
  entities: {
    ...state.entities,
    [payload.id]: payload
  }
});

export const setEntitiesAndPagination = <
  T,
  S extends EntityState<T> & PaginationState & StateWithStatus
>(
  adapter: EntityAdapter<T>
) => (state: S, action: ActionWithPayload<Pagination<T>>): S => {
  const { items, ...pagination } = action.payload;
  return adapter.setAll(items, toggleStatusSuccess({ ...state, pagination }));
};

export const asPayload = <T>() => (payload: T) => ({ payload });
