export enum ToastColors {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info'
}

export interface TriggerToastPayload {
  message: string;
  color?: ToastColors;
  dismissible?: boolean;
  dismissTime?: number;
  autoDismiss?: boolean;
  copy?: any;
  download?: any;
  refresh?: any;
  onClose?: any;
}

export class Toast {
  constructor(
    public id,
    public message,
    public color: ToastColors = ToastColors.success,
    public dismissible: boolean = true,
    public copy: any = null,
    public download: any = null,
    public refresh: any = null,
    public onClose?: any,
    public refreshCallback?: any
  ) {}
}
