import {StorageObject} from './storage.service';

export class InMemoryStorage implements StorageObject {
    private data = {};

    clear(): void {
        this.data = {};
    }

    getItem(key: string): any {
        return this.data.hasOwnProperty(key) ? this.data[key] : undefined;
    }

    removeItem(key: string): void {
        delete this.data[key];
    }

    setItem(key: string, value: any): void {
        this.data[key] = String(value);
    }
}
