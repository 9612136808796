import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white
} from './helpers';

export const sunrise: TrackingPageModel = {
  languageMenu: getLanguageMenu('Source Sans Pro', '#2b5f77ff'),
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Source Sans Pro', '#2b5f77ff')
  },
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('Source Sans Pro', '#6d788c')
  },
  background: {
    color: '#FFFFFF',
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/5c98f44cf3ccc_background.jpg'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    linksColor: '#2b5f77ff',
    linksUnderline: true,
    backgroundColor: '#ffffff66',
    headerBackground: '#ffffff66',
    footerBackground: '#ffffff66',
    borderColor: white,
    borderRadius: 10,
    borderWidth: 1,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Source Sans Pro', '#2b5f77ff')
  },
  orderCard: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: white,
    backgroundColor: '#ffffff66',
    shadow: 'none',
    footerBackground: '#ffffff66',
    headerBackground: '#ffffff66',
    linksColor: '#2b5f77ff',
    linksUnderline: true,
    ...getFontConfig('Source Sans Pro', '#2b5f77ff')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Nunito Sans', '#2b5f77ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Poppins', '#2b5f77ff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Source Sans Pro', '#2b5f77ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 16,
    ...getFontConfig('Nunito', '#2b5f77ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: white,
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: '#ffffff',
    button: {
      on: true,
      backgroundColor: '#ff7700',
      borderColor: white,
      borderRadius: 10,
      borderWidth: 3,
      shadow: 'none',
      ...getFontConfig('Mouse Memoirs', white)
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: '#ffffff66',
      borderColor: white,
      borderRadius: 10,
      borderWidth: 1,
      shadow: 'none',
      title: {
        alignment: 'center',
        fontSize: 20,
        ...getFontConfig('Nunito', '#2b5f77ff')
      },
      info: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Source Sans Pro', '#2b5f77ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ff7700ff',
    borderColor: white,
    borderRadius: 10,
    borderWidth: 3,
    shadow: 'none',
    ...getFontConfig('Mouse Memoirs', white)
  },
  detailsButton: {
    on: null,
    backgroundColor: '#ff7700ff',
    borderColor: white,
    borderRadius: 10,
    borderWidth: 3,
    shadow: 'none',
    ...getFontConfig('Mouse Memoirs', white)
  },
  trackButton: {
    on: null,
    backgroundColor: '#ff7700ff',
    borderColor: '#ee7227',
    borderRadius: 10,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Mouse Memoirs', white)
  },
  pageScroll: {
    scrollColor: '#ffffff75',
    borderRadius: 4,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(
    getAsset('/images/themes/5c98f44d00ab1_logo-sunrise-300x-2x.png')
  ),
  socialIcons: getSocialIcons('#2b5f77ff'),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: white,
    borderColor: '#ffffff66',
    borderRadius: 10,
    borderWidth: 1,
    sidebarField: 'orderNumbers',
    shadow: 'none',
    ...getFontConfig('Source Sans Pro', '#2b5f77ff')
  },
  footer: {
    linksColor: '#2B5F77',
    linksUnderline: true,
    backgroundColor: '#00ccff00',
    ...getFooterItems(),
    ...getFontConfig('Source Sans Pro', '#2b5f77ff')
  },
  header: {
    backgroundColor: '#00ccff00'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#ffffff66',
    borderRadius: 10,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff00',
      borderColor: white,
      borderRadius: 10,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#ff7700ff',
        borderColor: white,
        borderRadius: 10,
        borderWidth: 3,
        shadow: 'none',
        ...getFontConfig('Mouse Memoirs', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('Source Sans Pro', '#2b5f77ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Source Sans Pro', '#2b5f77ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Source Sans Pro', '#2b5f77ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#ff7700ff',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#ffffff66',
    borderColor: white,
    borderRadius: 10,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff00',
      borderColor: white,
      borderRadius: 10,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#ff7700ff',
        borderColor: white,
        borderRadius: 10,
        borderWidth: 3,
        shadow: 'none',
        ...getFontConfig('Mouse Memoirs', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('Source Sans Pro', '#2b5f77ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Source Sans Pro', '#2b5f77ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Source Sans Pro', '#2b5f77ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#ff7700ff',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
