import { HttpParameterCodec, HttpParams } from '@angular/common/http';
import {
  isArray,
  isEmpty,
  isFunction,
  isObject,
  isPlainObject,
  map,
  pickBy
} from 'lodash';
import { curry, equals, isNil, not } from 'ramda';
import { Observable } from 'rxjs';
import { first, map as RxMap } from 'rxjs/operators';
import { DynamicField } from '../admin/shared/dynamic-fields/interfaces';
import { get } from 'lodash';
import { ShipmentSelectOptions } from '../admin/dashboard/store/shipment-fields/shipment-field.model';
import { CookieStorage } from 'app/core/storage/storage/cookie-storage';
import { environment } from '../../environments/environment';
import data from '../../assets/i18n/languages.json';

/* eslint-disable */
export const AGREE_LABEL_TEXT =
  'I agree to the <a href="https://trackmage.com/terms-and-conditions/" target="_blank" title="Terms & Conditions">Terms & Conditions</a> ' +
  'and <a href="https://trackmage.com/privacy-policy/" target="_blank" title="Privacy Policy">Privacy Policy</a>';

export const isInstance = curry(
  (instance: any, value) => value instanceof instance
);

export const replaceStringParams = (string, params) =>
  Object.entries(params).reduce(
    (url, [key, value]) => url.replace(`:${key}`, value),
    string
  );

const encoder: HttpParameterCodec = {
  encodeKey: (key: string) => {
    return encodeURIComponent(key);
  },
  encodeValue: (value: string) => {
    return encodeURIComponent(value);
  },
  decodeKey: (key: string) => {
    return decodeURIComponent(key);
  },
  decodeValue: (value: string) => {
    return decodeURIComponent(value);
  }
};

export const addQueryParams = (
  queryParams: { [key: string]: any },
  params = new HttpParams({ encoder })
): HttpParams =>
  Object.entries(queryParams).reduce((prms, [key, value]) => {
    return isArray(value)
      ? value.reduce((acc, item) => acc.append(key, item), prms)
      : prms.append(key, value);
  }, params || new HttpParams({ encoder }));

export const isPrimitive = (value: any): boolean => typeof value !== 'object';

export const isNotNil = (value: any) => not(isNil(value));

export const isTruthy = (value) => equals(true, !!value);

export const notEquals = (value) => (comparator) =>
  not(equals(comparator, value));

export const returnValFn = (value) => value;

export const returnValCurryFn = (value) => () => value;

export const trackByKey =
  (key: string = 'id') =>
  (index: number, item: any) =>
    item[key];

export const trackByIdFn = (index: number, item: any) => item.id;

export const trackByIndex = (index: number) => index;

export const isObjectOnly = (value): boolean =>
  isObject(value) && !isArray(value) && !isFunction(value);

export type ReducerArg = [
  (previousValue, currentValue, currentIndex?: number, array?: any) => any,
  any
];

export const reduceEntries = curry(
  ([previousVal, currentVal]: ReducerArg, obj) =>
    isObjectOnly(obj)
      ? Object.entries(obj).reduce(previousVal, currentVal)
      : obj
);

export const filterKeysByValues = (object, predicate) =>
  Object.entries(object)
    .filter(([, value]) => predicate(value))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value
      }),
      {}
    );

export const filterValuesNotEmpty = (object) =>
  filterKeysByValues(object, isTruthy);

const secondsToHmsObject = (seconds) => ({
  hours: Math.floor(seconds / 3600),
  minutes: Math.floor((seconds % 3600) / 60),
  seconds: Math.floor((seconds % 3600) % 60)
});

const generateHourMinuteSecondsObject = ([first, ...rest]: any) => {
  if (first) {
    return first[1] === 0
      ? generateHourMinuteSecondsObject(<any>rest)
      : [first, ...rest].reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value < 10 ? `0${value}` : `${value}`
          }),
          {}
        );
  }
  return {};
};

export const secondsToDHMS = (secondsValue) => {
  if (!secondsValue) {
    return null;
  }
  let { hours, minutes, seconds } = generateHourMinuteSecondsObject(
    Object.entries(secondsToHmsObject(secondsValue))
  );
  let days;
  if (hours && hours >= 24) {
    days = hours && hours >= 24 ? Math.floor(hours / 24) : null;
    hours = hours - days * 24 === 0 ? '00' : hours - days * 24;
  }
  return pickBy({
    days,
    hours,
    minutes: minutes ? minutes : '0',
    seconds
  });
};

export const itemValueByKeyToString = (key: string) => (item) => ({
  ...item,
  [key]: item[key].toString()
});

export const mapItemValueByKeyToString = (key) => (items) =>
  items.map(itemValueByKeyToString(key));

export const sortListByOrderingList =
  (key = 'id') =>
  (list = [], ordering = []) => {
    const ordered = list.filter((item) => ordering.includes(item[key]));
    const unOrdered = list.filter((item) => !ordering.includes(item[key]));
    return [
      ...ordered.sort(
        (left, right) =>
          ordering.indexOf(left[key]) - ordering.indexOf(right[key])
      ),
      ...unOrdered
    ];
  };

export function sortFn(a, b): number {
  return a === b ? 0 : a < b ? -1 : 1;
}

export const isMobile = (width: number = 991) => {
  const mq = window.matchMedia(`(max-width: ${width}px)`);
  return mq.matches;
};

export const getFieldCodeById = (
  entities: { [key: string]: DynamicField },
  payload: number | string
): string =>
  (
    map<any>(entities).find(({ id }) => id === payload) || {
      code: null
    }
  ).code;

export function buildTeamDashboardRoute(
  [teamId, workspaceId],
  restUrl?: any[]
) {
  return [`/dashboard/teams`, teamId, 'workspaces', workspaceId, ...restUrl];
}

export function filterFieldsForTrackingPage(fields, tag?: string) {
  return fields.filter(
    (field) =>
      field.fieldType !== 'date' &&
      (field.tags && tag ? field.tags.includes(tag) : true)
  );
}

export function firstValueOf<T>(observable: Observable<T>): Promise<T> {
  return observable.pipe(first()).toPromise();
}

export function flattenObject(
  item: { [key: string]: any },
  nestedKey?: string
) {
  return Object.entries(item).reduce((acc, [key, value]) => {
    key = nestedKey ? `${nestedKey}.${key}` : key;
    return isPlainObject(value)
      ? { ...acc, ...flattenObject(value, key) }
      : { ...acc, [key]: value };
  }, {});
}

export function chunkObject(object: { [key: string]: any }) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    const [firstKey, nestedKey] = key.split('.');

    if (
      (firstKey === 'shipmentStatus' || firstKey === 'orderStatus') &&
      value === null
    ) {
      return { ...acc, [firstKey]: null };
    }

    value = nestedKey
      ? { ...(acc[firstKey] || {}), [nestedKey]: value }
      : value;

    return { ...acc, [firstKey]: value };
  }, {});
}

export function skipEmptyValues(object) {
  return pickBy(object, (value) => value !== '');
}

export function listToSelectOptions(
  list: any[],
  titleString: string = 'title',
  idString: string = 'id'
): any[] {
  return list.map((item) => ({
    title: item[titleString],
    id: item[idString]
  }));
}

export function rejectIfEmpty(callback?: () => any) {
  return RxMap((item) => {
    const noItem = isEmpty(item);
    if (typeof callback === 'function' && noItem) {
      callback();
    }
    return !noItem;
  });
}

export function dropPasswordIfNotFilled({ password, ...value }) {
  if (password && password.includes('*')) {
    return value;
  }

  return { ...value, password };
}

export function commaSeparatedStringToArray(string: string | null): string[] {
  return (string || '').split(',').filter(isTruthy);
}

export function dropRepeatsFromArray<T extends object>(
  array: T[],
  primaryKeyPath: string = 'id'
): T[] {
  return array.reduce((acc: T[], curr) => {
    return !!acc.find(
      (item) => get(item, primaryKeyPath) === get(curr, primaryKeyPath)
    )
      ? acc
      : [...acc, curr];
  }, []);
}

export function getSelectedOptionTitle(
  selectOptions: ShipmentSelectOptions,
  key: string
) {
  const { options, keyField, titleField } = selectOptions;

  const currentOption = options.find((option) => option[keyField] === key);

  return currentOption ? currentOption[titleField] : 'None';
}

/**
 * Check that value is defined and not null
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return <T>value !== undefined && <T>value !== null;
}

export function openWindow(link, features = '') {
  if (environment.testMode) {
    window.location.href = link;
  } else {
    window.open(link, '_blank', features);
  }
}

export function addLanguageToLink(link, lang = null) {
  if (null === lang) {
    return link;
  }
  const [url, query] = link.split('?');
  const queryParams = isDefined(query) ? query.split('&') : [];
  return `${url}?${[...queryParams, 'lang=' + lang].join('&')}`;
}

export function getCustomThemeId(): string {
  const customThemeId = CookieStorage.getItem('customThemeId');
  if (isDefined(customThemeId)) {
    CookieStorage.removeItem('customThemeId');
    return customThemeId;
  }
  return null;
}

export function removeCustomThemeId(): void {
  CookieStorage.setItem('customThemeId', null, {
    path: '/',
    domain: '.trackmage.com',
    expires: -1,
    sameSite: 'Lax',
    secure: true
  });
}

export function setCustomThemeId(themeId: string) {
  CookieStorage.setItem('customThemeId', themeId, {
    path: '/',
    domain: '.trackmage.com',
    expires: 60 * 60 * 24,
    sameSite: 'Lax',
    secure: true
  });
}

export function getMatomoFieldsFromCookies() {
  return {
    matomoId: CookieStorage.getItemStartingWith('_pk_id'),
    matomoReferrer: CookieStorage.getItemStartingWith('_pk_ref')
  };
}

export function getReferralFieldsFromCookies() {
  const affId = CookieStorage.getItem('wp_affiliates');
  const hit = CookieStorage.getItem('_h_affiliates');
  return {
    refTrackingId: isDefined(affId) && isDefined(hit) ? `${affId}|${hit}` : null
  };
}

export function randomPassword(length) {
  const chars =
    'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
  let pass = '';
  for (let x = 0; x < length; x++) {
    const i = Math.floor(Math.random() * chars.length);
    pass += chars.charAt(i);
  }
  return pass;
}

export function getActiveLanguages() {
  return data.filter(({ active }) => active === 'yes');
}

export function patchProductImages(productsToPatch, products) {
  if (
    products &&
    products.length > 0 &&
    productsToPatch &&
    productsToPatch.length > 0
  ) {
    return products.map((product, index) => {
      const patch = productsToPatch[index % productsToPatch.length];
      return { ...product, name: patch.title, imageUrl: patch.imageUrl };
    });
  }
  return products;
}

export const modifyHtml = (inputHtml) => {
  const parser = new DOMParser();
  const html = parser.parseFromString(inputHtml, 'text/html');
  const mentionSpans = html.querySelectorAll('.mention');
  if (!mentionSpans.length) {
    return inputHtml;
  }
  mentionSpans.forEach((span) => {
    const dataValue = span.getAttribute('data-value');
    const userId = span.getAttribute('data-id');
    span.replaceWith(`[${dataValue}](${userId})`);
  });
  return html.body.innerHTML;
};

export const modifyComment = (stringHtml) => {
  const pattern =
    /\[(.*?)\]\(([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})\)/gm;
  const resultString = stringHtml.replace(
    pattern,
    `<span class="mention" data-index="0" data-denotation-char="@" data-id="$2" data-value="$1"><span contenteditable="false">@$1</span></span>`
  );
  return resultString;
};
