import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { STORE_ROUTER_MODULE, ROUTER_SERIALIZER_PROVIDER } from './router';
import { RouterEffects } from '../effects';

import { reducers } from './root.reducer';
import { environment } from '../../../environments/environment';

export const ROOT_STORE_MODULES = [
  StoreModule.forRoot(reducers),
  EffectsModule.forRoot([RouterEffects]),
  StoreDevtoolsModule.instrument({
    maxAge: 50,
    logOnly: !environment.production
  }),
  STORE_ROUTER_MODULE
];

export { ROUTER_SERIALIZER_PROVIDER };

export { AppState } from './root.reducer';

export * from './router/router.selectors';
