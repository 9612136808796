import { openWindow } from 'app/helpers/functions';

const downloadNotSupported = () => {
  alert(
    `Your device does not support files downloading. Please try again in desktop browser.`
  );
};

const userAgentChromeOrSafari = (navigator): boolean => {
  const CHROME = 'chrome';
  const SAFARI = 'safari';

  const browserTypeIs = (agentName: string): boolean =>
    navigator.userAgent.toLowerCase().includes(agentName);

  return browserTypeIs(CHROME) || browserTypeIs(SAFARI);
};

const setLinkAttributes = (link, url, filename) => {
  link.href = url;
  link.target = '_blank';
  if ('download' in link) {
    link.download =
      filename || url.substring(url.lastIndexOf('/') + 1, url.length);
  }
  const styles = [
    ['visibility', 'hidden'],
    ['width', '0'],
    ['height', '0'],
    ['position', 'relative'],
    ['z-index', '-1']
  ];
  styles.map(([propName, value]) => (link.style[propName] = value));
};

const clickLinkElement = link => {
  if (document.createEvent) {
    const e = new MouseEvent('click', { view: window });
    link.dispatchEvent(e);
  }
};

const addLink = link => {
  document.body.appendChild(link);
};

const removeLink = link => {
  document.body.removeChild(link);
};

const downloadWithLinkTag = (url, filename) => {
  const link = document.createElement('a');
  setLinkAttributes(link, url, filename);
  addLink(link);
  clickLinkElement(link);
  removeLink(link);
};

const downloadWithWindowOpen = url => {
  if (url.indexOf('?') === -1) {
    url += '?download';
  }
  openWindow(url);
};

export const fileDownloadFactory = (
  navigator: Navigator = window.navigator
): ((url: string, filename?: string) => void) =>
  /(iP)/g.test(navigator.userAgent)
    ? downloadNotSupported
    : userAgentChromeOrSafari(navigator)
    ? downloadWithLinkTag
    : downloadWithWindowOpen;

export const blobDownloadFactory = (windowObj: any = window) => {
  // if (!!window.navigator.msSaveBlob) {
  //   return (blob, filename) => window.navigator.msSaveBlob(blob, filename);
  // }
  const URL = windowObj.URL;

  if (!URL) {
    return downloadNotSupported;
  }

  return (blob, name) => {
    const url = URL.createObjectURL(blob);

    // Test for download link support
    if ('download' in document.createElement('a')) {
      var a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', name);

      // Simulate a click to trigger download
      var event = new MouseEvent('click', { view: window });
      a.dispatchEvent(event);
    } else {
      // fallover, open resource in new tab.
      openWindow(url);
    }
  };
};
