import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white
} from './helpers';

export const artstore: TrackingPageModel = {
  languageMenu: getLanguageMenu('Josefin Sans', '#000000ff'),
  searchPageInput: {
    backgroundColor: '#ffffff',
    borderColor: '#ced4da',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Josefin Sans', '#d1d1d1ff')
  },
  searchPageTitle: {
    fontSize: 35,
    ...getFontConfig('Josefin Sans', '#000000ff', '700')
  },
  background: {
    color: '#ffffff',
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/art-store.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: '#ffffff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Josefin Sans', '#000000ff', '700')
  },
  orderCard: {
    backgroundColor: '#ffffff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 16,
    ...getFontConfig('Lobster', '#0bb7f3ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 23,
    ...getFontConfig('Josefin Sans', '#000000ff', '700')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Josefin Sans', '#6d6d6dff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 18,
    ...getFontConfig('Josefin Sans', '#dc6903ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    scroll: false,
    itemsLimit: 1,
    borderColor: '#ffb00aff',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: '#ffffff',
    shadow: 'none',
    button: {
      on: true,
      backgroundColor: '#f2ab02ff',
      borderColor: '#000000ff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      ...getFontConfig('Josefin Sans', '#000000ff', '700')
    },
    itemCard: {
      backgroundColor: '#ffffff00',
      borderColor: '#2E4068',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Josefin Sans', '#000000ff', '700')
      },
      info: {
        alignment: 'left',
        fontSize: 14,
        ...getFontConfig('Josefin Sans', '#909090ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffad01ff',
    borderColor: '#2e4068ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Josefin Sans', '#000000ff', '700')
  },
  detailsButton: {
    backgroundColor: '#2fd2f6ff',
    borderColor: '#5780ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Josefin Sans', white, '700')
  },
  trackButton: {
    backgroundColor: '#27d9eeff',
    borderColor: '#ee7227',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Josefin Sans', white, '700')
  },
  pageScroll: {
    scrollColor: '#2E4068',
    borderRadius: 2,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/art-store-logo.png')),
  socialIcons: getSocialIcons('#000000ff', false),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#ffffff',
    borderColor: '#CED2DC',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    sidebarField: null,
    ...getFontConfig('Josefin Sans', '#000000ff')
  },
  footer: {
    linksColor: '#ffa012ff',
    linksUnderline: false,
    backgroundColor: '#000000ff',
    ...getFooterItems(),
    ...getFontConfig('Rubik', '#ffffffff')
  },
  header: {
    backgroundColor: '#ffffffff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: false,
    backgroundColor: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffffff',
      borderColor: '#ffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#0da3faff',
        borderColor: '#5780ffff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Ubuntu', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Josefin Sans', '#000000ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Josefin Sans', '#515151ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 18,
        ...getFontConfig('Lobster', '#ea8420ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('art-store'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#e10a56ff',
      arrowSize: 30
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffffff',
      borderColor: '#ffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#0da3faff',
        borderColor: '#5780ffff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Ubuntu', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Josefin Sans', '#000000ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Josefin Sans', '#515151ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 18,
        ...getFontConfig('Lobster', '#ea8420ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('art-store'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#e10a56ff',
      arrowSize: 30
    }
  },
  seoTitle: ''
};
