import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { routerReducer as router } from '@ngrx/router-store';
import { State as RouterState } from './router';

export interface AppState {
  router: RouterState;
}

export const reducers: ActionReducerMap<AppState> = {
  router
};

export function clearState(r: ActionReducer<any>) {
  return (state, action) => {
    if (action.type === '[Core] Log Out') {
      return r({ router: state.router }, action);
    }
    return r(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearState];
