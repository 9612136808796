import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Toast } from './toast.model';
import { copyTextToClipboard, download } from 'app/helpers';

@Component({
  selector: 'app-toast',
  template: `
    <div class="toast" [ngClass]="toast?.color">
      <div class="w-100">
        <div class="toast-message" [innerHtml]="toast?.message"></div>
        <div class="toast-buttons">
          <button
            *ngIf="toast.copy"
            class="btn btn-transp toast-button"
            (click)="copyToClipBoard()"
          >
            {{ copied ? '+' : 'Tracking Page Link' }}
          </button>
          <button
            *ngIf="toast.refresh"
            class="btn btn-transp toast-button"
            (click)="callRefresh()"
          >
            Refresh
          </button>
          <button
            *ngIf="toast.download"
            class="btn btn-transp toast-button"
            (click)="donwloadFile()"
          >
            Download
          </button>
        </div>
      </div>
      <div *ngIf="toast?.dismissible" class="toast-close-container">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="toastDismiss.emit(toast)"
        >
          <i class="toast-close fa fa-times" aria-hidden="true"> </i>
        </button>
      </div>
    </div>
  `
})
export class ToastComponent {
  @Input() toast: Toast;
  @Output() toastDismiss: EventEmitter<Toast> = new EventEmitter();

  copied: boolean;

  copyToClipBoard() {
    copyTextToClipboard(this.toast.copy).then(() => {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    });
  }

  donwloadFile() {
    download(this.toast.download);
  }

  callRefresh() {
    if (this.toast.refreshCallback) {
      this.toast.refreshCallback();
    }
  }
}
