import {
  animate,
  keyframes,
  style,
  transition,
  trigger
} from '@angular/animations';

export const slideInRight = trigger('slideInRight', [
  transition('void => *', [
    animate(
      '.5s ease-in-out',
      keyframes([
        style({ transform: 'translateX(200%)', offset: 0 }),
        style({ transform: 'translateX(0)', offset: 1 })
      ])
    )
  ])
]);

export const slideOutLeft = trigger('slideOutLeft', [
  transition('* => void', [
    animate(
      '.5s ease-in-out',
      keyframes([
        style({ transform: 'translateX(0)', offset: 0 }),
        style({ transform: 'translateX(-200%)', offset: 1 })
      ])
    )
  ])
]);

export const slideInLeft = trigger('slideInLeft', [
  transition('void => *', [
    animate(
      '.5s ease-in-out',
      keyframes([
        style({ transform: 'translateX(-200%)', offset: 0 }),
        style({ transform: 'translateX(0)', offset: 1 })
      ])
    )
  ])
]);

export const slideOutRight = trigger('slideOutRight', [
  transition('* => void', [
    animate(
      '.5s ease-in-out',
      keyframes([
        style({ transform: 'translateX(0)', offset: 0 }),
        style({ transform: 'translateX(200%)', offset: 1 })
      ])
    )
  ])
]);

export const SLIDING_ANIMATIONS = [
  slideInLeft,
  slideInRight,
  slideOutLeft,
  slideOutRight
];
