import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastModule } from 'app/core/toast';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppGuard } from './app.guard';
import { StorageService } from './core';
import { RouterEffects } from './core/effects/router.effects';
import { metaReducers, reducers } from './core/reducers/root.reducer';
import {
  ROUTER_SERIALIZER_PROVIDER,
  STORE_ROUTER_MODULE
} from './core/reducers/router';
import { SENTRY_PROVIDER } from './core/sentry-error-handler';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatomoModule } from 'ngx-matomo';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from '../environments/environment';
import { MATOMO_MODULE_CONFIG } from './helpers';

export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

declare let window: {
  [key: string]: any;
  prototype: Window;
  new (): Window;
};

const disableMatomoHeatmapSession = () => {
  window._paq = window._paq || [];
  window._paq.push(['HeatmapSessionRecording::disable']);
  window._paq.push(['HeatmapSessionRecording::disableAutoDetectNewPageView']);
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    STORE_ROUTER_MODULE,
    BrowserModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([RouterEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: !environment.production
    }),
    ToastModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatomoModule.forRoot(MATOMO_MODULE_CONFIG),
    NgxGoogleAnalyticsModule.forRoot(environment.googleConversionId),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    })
  ],
  providers: [
    ROUTER_SERIALIZER_PROVIDER,
    SENTRY_PROVIDER,
    StorageService,
    AppGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    disableMatomoHeatmapSession();
  }
}
