import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from '@angular/core';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: any;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    route = this.parseRouteChild(route);

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params, data } = route;

    return { url, params, queryParams, data };
  }

  public parseRouteChild(route) {
    return route && route.firstChild
      ? this.parseRouteChild(route.firstChild)
      : route;
  }
}

export const ROUTER_SERIALIZER_PROVIDER = [
  { provide: RouterStateSerializer, useClass: CustomSerializer }
];
