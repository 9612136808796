import { TrackingPageModel } from '../tracking-page.model';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getAsset,
  getLanguageMenu,
  white,
  getProductItems
} from './helpers';

export const cityVelo: TrackingPageModel = {
  languageMenu: getLanguageMenu('Open Sans', '#ffffffff'),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('Open Sans', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Open Sans', '#ffffffff')
  },
  background: {
    color: white,
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/5c98e099d2acd_background.jpg'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    linksColor: '#f26241ff',
    linksUnderline: true,
    backgroundColor: '#fffffff2',
    headerBackground: '#fffffff2',
    footerBackground: '#fffffff2',
    borderColor: '#d5d9e1',
    borderRadius: 2,
    borderWidth: 0,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Open Sans', '#1b100eff')
  },
  orderCard: {
    borderRadius: 2,
    borderWidth: 2,
    borderColor: '#d5d9e1',
    backgroundColor: '#fffffff2',
    shadow: 'none',
    headerBackground: '#fffffff2',
    footerBackground: '#fffffff2',
    linksColor: '#f26241ff',
    linksUnderline: false,
    ...getFontConfig('Open Sans', '#1b100eff')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 11,
    ...getFontConfig('Open Sans', '#1b100eff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Open Sans', '#1b100eff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 11,
    ...getFontConfig('Open Sans', '#a8a8a8ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 11,
    ...getFontConfig('Open Sans', '#1b100eff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: '#d5d9e1',
    borderRadius: 2,
    borderWidth: 2,
    backgroundColor: '#ffffff',
    button: {
      on: true,
      backgroundColor: '#ffffff',
      borderColor: white,
      borderRadius: 2,
      borderWidth: 2,
      shadow: 'none',
      ...getFontConfig('Quantico', '#000000')
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: '#ffffff00',
      borderColor: '#2E4068',
      borderRadius: 2,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Open Sans', '#000000ff')
      },
      info: {
        alignment: 'left',
        fontSize: 15,
        ...getFontConfig('Open Sans', '#535353ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: white,
    borderRadius: 2,
    borderWidth: 2,
    shadow: 'none',
    ...getFontConfig('Quantico', white)
  },
  detailsButton: {
    on: null,
    backgroundColor: '#1b100eff',
    borderColor: '#00ccff00',
    borderRadius: 2,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Quantico', white)
  },
  trackButton: {
    on: null,
    backgroundColor: '#1b100eff',
    borderColor: '#ee7227',
    borderRadius: 2,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Quantico', white)
  },
  pageScroll: {
    scrollColor: '#ffffff75',
    borderRadius: 4,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/5c98e099c6f89_logo-1-114x-2x.png')),
  socialIcons: getSocialIcons(white),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: white,
    borderColor: white,
    borderRadius: 2,
    borderWidth: 0,
    sidebarField: 'orderNumbers',
    shadow: 'none',
    ...getFontConfig('Open Sans', '#1b100eff')
  },
  footer: {
    linksColor: white,
    linksUnderline: true,
    backgroundColor: '#00ccff00',
    ...getFontConfig('Open Sans', '#ffffffff'),
    ...getFooterItems()
  },
  header: {
    backgroundColor: '#f26241ff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: '#fffffff2',
    borderColor: '#d5d9e1',
    borderRadius: 2,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff00',
      borderColor: '#d5d9e1',
      borderRadius: 2,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#1b100eff',
        borderColor: '#00ccff00',
        borderRadius: 2,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Quantico', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Open Sans', '#1b100eff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Open Sans', '#1b100eff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Open Sans', '#1b100eff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#1b100eff',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#fffffff2',
    borderColor: '#d5d9e1',
    borderRadius: 2,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff00',
      borderColor: '#d5d9e1',
      borderRadius: 2,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#1b100eff',
        borderColor: '#00ccff00',
        borderRadius: 2,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Quantico', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Open Sans', '#1b100eff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Open Sans', '#1b100eff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Open Sans', '#1b100eff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#1b100eff',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
