import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white
} from './helpers';

export const dogslove: TrackingPageModel = {
  languageMenu: getLanguageMenu('Nunito', '#ffffffff', '700'),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ffffffff',
    borderWidth: 0,
    borderRadius: 22,
    ...getFontConfig('Nunito', '#58a4c4ff')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Nunito', '#ffffffff', '700')
  },
  background: {
    color: '#58a4c4ff',
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/dogs-love.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    linksColor: '#dfa96cff',
    linksUnderline: false,
    backgroundColor: white,
    headerBackground: white,
    footerBackground: white,
    borderColor: '#ffffffff',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Nunito', '#2d667dff', '700')
  },
  orderCard: {
    borderRadius: 15,
    borderWidth: 0,
    borderColor: '#d5d9e100',
    backgroundColor: white,
    shadow: 'none',
    footerBackground: white,
    headerBackground: white,
    linksColor: '#5780ff',
    linksUnderline: false,
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 24,
    ...getFontConfig('Nunito', '#dfa96cff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Nunito', '#2d667dff', '800')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 14,
    ...getFontConfig('Nunito', '#58a4c4ff', '500')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Nunito', '#ff7272ff', '700')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: '#58a4c4ff',
    borderRadius: 15,
    borderWidth: 1,
    backgroundColor: '#58a4c4ff',
    button: {
      on: true,
      backgroundColor: '#417e98ff',
      borderColor: '#2e4068ff',
      borderRadius: 22,
      borderWidth: 0,
      shadow: 'none',
      ...getFontConfig('Nunito', white)
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: '#ffffff00',
      borderColor: '#2e406800',
      borderRadius: 15,
      borderWidth: 1,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Nunito', '#ffffffff', '700')
      },
      info: {
        alignment: 'left',
        fontSize: 12,
        ...getFontConfig('Nunito', '#ffffffff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffffff',
    borderColor: '#2e406800',
    borderRadius: 22,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Nunito Sans', '#58a4c4ff', '900')
  },
  detailsButton: {
    on: null,
    backgroundColor: '#58a4c4ff',
    borderColor: '#58a4c4ff',
    borderRadius: 22,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Nunito', white, '700')
  },
  trackButton: {
    on: null,
    backgroundColor: '#f37a19ff',
    borderColor: '#ee7227ff',
    borderRadius: 21,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Nunito', white, '700')
  },
  pageScroll: {
    scrollColor: '#2E4068',
    borderRadius: 2,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/dogs-love-logo.png')),
  socialIcons: getSocialIcons('#ffffffff', false),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: white,
    borderColor: '#ced2dc00',
    borderRadius: 15,
    borderWidth: 0,
    sidebarField: null,
    shadow: 'none',
    ...getFontConfig('Nunito', '#2e6368ff')
  },
  footer: {
    linksColor: '#ffffffff',
    linksUnderline: false,
    backgroundColor: '#ffc98cff',
    ...getFooterItems(),
    ...getFontConfig('Nunito', '#ffffffff')
  },
  header: {
    backgroundColor: '#58a4c4ff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: false,
    backgroundColor: '#58a4c4ff',
    borderColor: '#d5d9e100',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#ffffff',
      borderRadius: 15,
      borderWidth: 5,
      shadow: 'none',
      button: {
        backgroundColor: '#f9b66aff',
        borderColor: '#5780ff00',
        borderRadius: 19,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Nunito Sans', white, '700')
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'left',
        fontSize: 15,
        ...getFontConfig('Nunito Sans', '#2d667dff', '700')
      },
      regularPrice: {
        alignment: 'left',
        fontSize: 10,
        ...getFontConfig('Nunito Sans', '#ff7272ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 17,
        ...getFontConfig('Nunito Sans', '#3eac2eff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('dogs-love'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#ffffffff',
      arrowSize: 30
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#58a4c4ff',
    borderColor: '#d5d9e100',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#ffffff',
      borderRadius: 15,
      borderWidth: 5,
      shadow: 'none',
      button: {
        backgroundColor: '#f9b66aff',
        borderColor: '#5780ff00',
        borderRadius: 19,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Nunito Sans', white, '700')
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'left',
        fontSize: 15,
        ...getFontConfig('Nunito Sans', '#2d667dff', '700')
      },
      regularPrice: {
        alignment: 'left',
        fontSize: 10,
        ...getFontConfig('Nunito Sans', '#ff7272ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 17,
        ...getFontConfig('Nunito Sans', '#3eac2eff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('dogs-love'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#ffffffff',
      arrowSize: 30
    }
  },
  seoTitle: ''
};
