import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from 'environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

function resetHeight() {
  // reset the body height to that of the inner browser
  document.body.style.height = window.innerHeight + 'px';
}
// reset the height whenever the window's resized
window.addEventListener('resize', resetHeight);
// called to initially set the height.
resetHeight();
