import { TrackingPageModel } from '../tracking-page.model';
import { defaultTheme } from './default';
import { brooklyn } from './brooklyn';
import { fairyCake } from './fairy-cake';
import { simple } from './simple';
import { digital } from './digital';
import { nova } from './nova';
import { vintage } from './vintage';
import { snowdevil } from './snowdevil';
import { bliss } from './bliss';
import { play } from './play';
import { sparo } from './sparo';
import { modern } from './modern';
import { boxingBull } from './boxing-bull';
import { cityVelo } from './city-velo';
import { ratio } from './ratio';
import { uptown } from './uptown';
import { number22 } from './number-22';
import { lightroom } from './lightroom';
import { empire } from './empire';
import { beauty } from './beauty';
import { sunrise } from './sunrise';
import { bakerhouse } from './baker-house';
import { dogslove } from './dogs-love';
import { food } from './food';
import { luxurybrand } from './luxury-brand';
import { seoul } from './seoul';
import { toystore } from './toy-store';
import { yourstyle } from './your-style';
import { fitnessstore } from './fitness-store';
import { artstore } from './art-store';
import { carparts } from './car-parts';
import { furniture } from './furniture';
import { tourism } from './tourism';
import { tech } from './tech';
import { kitchenware } from './kitchenware';

export type DefaultThemeIds =
  | 'default'
  | 'tech'
  | 'kitchenware'
  | 'carparts'
  | 'furniture'
  | 'tourism'
  | 'brooklyn'
  | 'bakerhouse'
  | 'dogslove'
  | 'food'
  | 'luxurybrand'
  | 'seoul'
  | 'toystore'
  | 'yourstyle'
  | 'fitnessstore'
  | 'artstore'
  | 'fairyCake'
  | 'simple'
  | 'digital'
  | 'nova'
  | 'vintage'
  | 'snowdevil'
  | 'bliss'
  | 'play'
  | 'sparo'
  | 'modern'
  | 'boxingBull'
  | 'cityVelo'
  | 'ratio'
  | 'uptown'
  | 'number22'
  | 'lightroom'
  | 'empire'
  | 'beauty'
  | 'sunrise'
  | 'default'
  | null;

export const THEME_KEYS = [
  'default',
  'tech',
  'kitchenware',
  'carparts',
  'furniture',
  'tourism',
  'bakerhouse',
  'dogslove',
  'food',
  'luxurybrand',
  'seoul',
  'toystore',
  'yourstyle',
  'fitnessstore',
  'artstore',
  'brooklyn',
  'fairyCake',
  'simple',
  'digital',
  'nova',
  'vintage',
  'snowdevil',
  'bliss',
  'play',
  'sparo',
  'modern',
  'boxingBull',
  'cityVelo',
  'ratio',
  'uptown',
  'number22',
  'lightroom',
  'empire',
  'beauty',
  'sunrise'
];

export const THEME_IMAGES = {
  custom: '/assets/themes/custom.png',
  default: '/assets/themes/default.png',
  tech: '/assets/themes/tech.png',
  kitchenware: '/assets/themes/kitchenware.png',
  carparts: '/assets/themes/car-parts.png',
  furniture: '/assets/themes/furniture.png',
  tourism: '/assets/themes/tourism.png',
  bakerhouse: '/assets/themes/baker-house.png',
  dogslove: '/assets/themes/dogs-love.png',
  food: '/assets/themes/food.png',
  luxurybrand: '/assets/themes/luxury-brand.png',
  seoul: '/assets/themes/seoul.png',
  toystore: '/assets/themes/toy-store.png',
  yourstyle: '/assets/themes/your-style.png',
  fitnessstore: '/assets/themes/fitness-store.png',
  artstore: '/assets/themes/art-store.png',
  brooklyn: '/assets/themes/brooklyn.png',
  fairyCake: '/assets/themes/fairycake.png',
  simple: '/assets/themes/simple.png',
  digital: '/assets/themes/digital.png',
  nova: '/assets/themes/nova.png',
  vintage: '/assets/themes/vintage.png',
  snowdevil: '/assets/themes/snowdevil.png',
  bliss: '/assets/themes/bliss.png',
  play: '/assets/themes/play.png',
  sparo: '/assets/themes/sparo.png',
  modern: '/assets/themes/modern.png',
  boxingBull: '/assets/themes/boxing-bull.png',
  cityVelo: '/assets/themes/cityvelo.png',
  ratio: '/assets/themes/ratio.png',
  uptown: '/assets/themes/uptown.png',
  number22: '/assets/themes/n-22.png',
  lightroom: '/assets/themes/lightroom.png',
  empire: '/assets/themes/empire.png',
  beauty: '/assets/themes/beauty.png',
  sunrise: '/assets/themes/sunrise.png'
};

export const THEMES: { [key in DefaultThemeIds]: TrackingPageModel } = {
  default: defaultTheme,
  tech,
  kitchenware,
  bakerhouse,
  carparts,
  furniture,
  tourism,
  dogslove,
  food,
  luxurybrand,
  seoul,
  toystore,
  yourstyle,
  fitnessstore,
  artstore,
  brooklyn,
  fairyCake,
  simple,
  digital,
  nova,
  vintage,
  snowdevil,
  bliss,
  play,
  sparo,
  modern,
  boxingBull,
  cityVelo,
  ratio,
  uptown,
  number22,
  lightroom,
  empire,
  beauty,
  sunrise
};

export const getTheme = (themeId: DefaultThemeIds): TrackingPageModel =>
  THEMES[themeId];
