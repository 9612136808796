import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AppGuard } from './app.guard';

const routes: Routes = [
  {
    path: 'tracking-page',
    loadChildren: () =>
      import('./tracking-page/tracking-page.module').then(
        m => m.TrackingPageModule
      )
  },
  {
    path: 'tp',
    loadChildren: () =>
      import('./tracking-page/tracking-page.module').then(
        m => m.TrackingPageModule
      )
  },
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canLoad: [AppGuard]
  }
];

export function errorHandler(error) {
  if (error.toString().includes('Loading chunk')) {
    window.location.reload();
  } else {
    console.error('Router Error ->', error);
  }
}

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: QuicklinkStrategy,
  errorHandler
};

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, routingConfiguration)
  ],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {}
