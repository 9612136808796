export const environment = {
  production: true,
  appVersion: 'dev',
  apiUrl: 'https://api.rc.trackmage.com',
  imgUrl: 'https://s3-stage.trackmage.com',
  widgetsUrl: 'https://s3.trackmage.com/trackmage-widgets',
  clientId: '1_trackmage-public-client',
  testMode: false,
  googleFontsKey: 'AIzaSyCg1KpAuAYvaEXDvxae3y4b8jpn5DmO5Ko',
  appUrl: 'https://app.trackmage.com',
  domain: 'trackmage.com',
  siteKey: '6Lc6VqYZAAAAADf9PQnriZZ0QTSC-ddrhKVA8L08',
  assetsUrl: 'https://s3-assets.trackmage.com',
  domainNotFoundRedirect: 'https://trackmage.com/not-found/',
  chargebeeSite: 'trackmage-test',
  billingEnabled: true,
  captchaEnabled: false,
  matomoUrl: '//vega.dev.trackmage.com/',
  matomoSiteId: 2,
  matomoJsLoader: 'mjs.php',
  matomoPhpLoader: 'mm.php',
  googleConversionId: 'AW-10838606309',
  googleConversionSignupStep1: 's_5MCLzNsNcDEOWDoLAo',
  googleConversionSignupStep2: 'wsNqCL_NsNcDEOWDoLAo',
  googleConversionSignupStep3: '1T5jCMLNsNcDEOWDoLAo',
  googleConversionEmailConfirmed: 'WciyCMXNsNcDEOWDoLAo',
  posthogToken: 'phc_pUa9CgwI4YLjiuIfbQRA81l9PiHdp0EByKd60VaXiSm',
  posthogUrl: 'https://app.posthog.com',
  usetifulEnabled: false,
  usetifulToken: 'b0fc91c313ae417c0b7ef24ec413e8b1',
  usetifulChecklistSetupTrackMage: '01GX68CG9VXDPNQKW684WAXBN8',
  usetifulChecklistCustomizeTP: '01GRK5VF69EKQ1FGN6DZJPZSQ8',
  usetifulChecklistCustomizeTPShopify: '01GWA0PY72F1PK4J34F7Y0TKVQ',
  usetifulChecklistCustomizeTPWooCom: '01GWMCDZCFQTWXQ0W24XY7GR1Y',
  usetifulChecklistSetupTrackMageReadUser: '01G6N44Q0KYHTFH8WJRZTF0KG4',
  usetifulChecklistSetupTrackMageWriteUser: '01GXRH475C4XWSTPKNHNEWK3Y6',
  usetifulShopifyTour: 30832,
  usetifulOrdersTour: 32920,
  usetifulOrdersTourShopify: 35628,
  usetifulShipmentsTour: 32919,
  usetifulOrdersTourReadUser: 34890,
  usetifulShipmentsTourReadUser: 34889
};
