import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class DestroyChecker implements OnDestroy, OnInit {
  public destroyed$: Subject<any> = new Subject();

  ngOnDestroy() {
    this.destroyed$.next();
  }

  ngOnInit() {}
}
