import { Action } from '@ngrx/store';

export interface PaginationPartial {
  next?: string;
  current?: any;
  first?: string;
  last?: any;
  totalItems?: number;
  previous?: string;
  perPage?: string;
}

export interface PaginationPayload {
  page: number;
  itemsPerPage: number;
}

export interface PaginationParams {
  page?: number;
  itemsPerPage?: number;
}

export interface PaginationAction extends Action {
  payload: PaginationPayload;
}
export interface PaginationResultAction<T> extends Action {
  payload: Pagination<T>;
}
export interface Pagination<T> extends PaginationPartial {
  items: T[];
}

export interface PaginationState {
  pagination: PaginationPartial;
}

export const selectPaginationState = (
  state: PaginationState
): PaginationPartial => state.pagination;
