import { environment } from '../../environments/environment';

export const MATOMO_MODULE_CONFIG = {
  scriptUrl: environment.matomoUrl + environment.matomoJsLoader,
  trackers: [
    {
      trackerUrl: environment.matomoUrl + environment.matomoPhpLoader,
      siteId: environment.matomoSiteId
    }
  ],
  routeTracking: {
    enable: true
  },
  scriptVersion: 4
};
