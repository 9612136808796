import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { omit } from 'lodash';
import { generate } from 'shortid';

import { Toast, TriggerToastPayload, ToastColors } from './toast.model';

interface ToastStorage {
  [key: string]: Toast;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts$ = new BehaviorSubject<ToastStorage>({});

  get toasts(): Observable<Toast[]> {
    return this.toasts$.asObservable().pipe(
      map(toasts => Object.values(toasts)),
      map(toasts =>
        toasts.sort(
          (left, right) =>
            this.toastIndexes.indexOf(left.id) -
            this.toastIndexes.indexOf(right.id)
        )
      )
    );
  }

  private toastIndexes = [];

  addToast({
    message,
    color,
    dismissible,
    autoDismiss,
    dismissTime = 3000,
    copy,
    download,
    refresh,
    onClose
  }: TriggerToastPayload) {
    const toast = new Toast(
      generate(),
      message,
      color,
      dismissible,
      copy,
      download,
      refresh,
      onClose
    );

    this.setIndexesAndUpdateToasts(toast);

    if (autoDismiss) {
      this.setAutoDismiss(toast, dismissTime);
    }

    return toast;
  }

  setIndexesAndUpdateToasts(toast: Toast): void {
    this.toastIndexes = [...this.toastIndexes, toast.id];
    this.toasts$.next({ ...this.toasts$.value, [toast.id]: toast });
  }

  setAutoDismiss(toast, time): void {
    interval(time)
      .pipe(first())
      .subscribe(() => this.removeToast(toast));
  }

  removeToast(toast) {
    if (toast.onClose) {
      toast.onClose();
    }
    this.toasts$.next(omit(this.toasts$.value, toast.id));
  }

  removeAllToasts() {
    this.toasts$.next({});
  }

  generateToastMessage(title, message): string {
    const withTitle =
      [null, ''].indexOf(title) === -1
        ? `<h3 class="toast-title">
        ${title}
      </h3>`
        : '';
    return `${withTitle}
      <p class="toast-description">
        ${message}
      </p>
    `;
  }

  triggerSuccessToast(message, dismissTime = 5000): Toast {
    return this.addToast({
      message,
      color: ToastColors.success,
      dismissible: true,
      autoDismiss: true,
      dismissTime
    });
  }

  triggerWarningToast(message, dismissTime = 5000): Toast {
    return this.addToast({
      message,
      color: ToastColors.warning,
      dismissible: true,
      autoDismiss: true,
      dismissTime
    });
  }

  triggerInfoToast(message, refresh = null, dismissTime = 5000): Toast {
    return this.addToast({
      message,
      color: ToastColors.info,
      dismissible: true,
      autoDismiss: true,
      refresh,
      dismissTime
    });
  }

  triggerDangerToast(
    message,
    dismissTime = 5000,
    onClose = null,
    autodismiss = false
  ): Toast {
    return this.addToast({
      message,
      color: ToastColors.danger,
      dismissible: true,
      autoDismiss: autodismiss || onClose ? true : false,
      dismissTime,
      onClose
    });
  }
}
