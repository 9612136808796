export enum PresetType {
  LAST_SEVEN_DAYS,
  THIS_MONTH,
  THIS_QUARTER,
  THIS_YEAR,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_YEAR
}

export interface DatePreset {
  title: string;
  id: PresetType;
}
