import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: 'https://8a5312f92d6a42f48fe1ef7ad73e2262@sentry.dev.trackmage.com/2'
    });
    Sentry.configureScope(scope =>
      scope.setTag('appVersion', environment.appVersion)
    );
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
    console.error('ERROR CAUGHT ->', error.originalError || error);
    // throw error;
  }
}

export const SENTRY_PROVIDER = environment.production
  ? [
      {
        provide: ErrorHandler,
        useClass: SentryErrorHandler
      }
    ]
  : [];
