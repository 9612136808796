import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './router.reducer';
import { RouterStateUrl } from './router-serializer';

const selectRouterStateSelector = createFeatureSelector<State>('router');
const selectRouterState = createSelector(
  selectRouterStateSelector,
  (state: State): RouterStateUrl => (!!state && state.state) || ({} as any)
);

const selectUrlAndParamsState = (state: RouterStateUrl) => ({
  url: state.url,
  params: state.params
});

export const selectUrlAndParams = createSelector(
  selectRouterState,
  selectUrlAndParamsState
);

export const selectUrlParamsState = (state: RouterStateUrl) => state.params;
export const selectUrlParams = createSelector(
  selectRouterState,
  selectUrlParamsState
);

const selectDataState = (state: RouterStateUrl) => state.data;
export const selectData = createSelector(selectRouterState, selectDataState);
