import { Action, Store, select, MemoizedSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { firstValueOf } from '../functions';
import { filter } from 'rxjs/operators';

export class BaseFacade<A extends Action, S> {
  constructor(protected store: Store<S>) {}

  protected dispatch(action) {
    this.store.dispatch(action);
  }

  protected select<R>(selector: MemoizedSelector<S, R>): Observable<R> {
    return this.store.pipe(select(selector));
  }

  public readFirst<R>(observable: Observable<R>): Promise<R> {
    return firstValueOf(observable);
  }

  public once<R>(observable: Observable<R>): Observable<R> {
    return observable.pipe(
      filter(value => value !== undefined && value !== null)
    );
  }
}
