import { TrackingPageModel } from '../tracking-page.model';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getAppImage,
  getAsset,
  getLanguageMenu,
  white,
  getProductItems
} from './helpers';

export const digital: TrackingPageModel = {
  languageMenu: getLanguageMenu('Montserrat', white),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('Montserrat', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Montserrat', '#585858ff')
  },
  background: {
    color: '#eff1f3ff',
    useBackgroundImage: false,
    imageUrl: getAppImage('/assets/img/tracking-page/Img_map_white.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: '#5858580d',
    headerBackground: '#5858580d',
    footerBackground: '#5858580d',
    borderColor: '#58585833',
    borderRadius: 3,
    borderWidth: 1,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    linksColor: '#528ec1ff',
    linksUnderline: false,
    ...getFontConfig('Montserrat', '#585858ff')
  },
  orderCard: {
    borderRadius: 3,
    borderWidth: 2,
    borderColor: '#58585833',
    backgroundColor: '#ffffffff',
    shadow: 'none',
    footerBackground: '#5858580d',
    headerBackground: '#5858580d',
    linksColor: '#528ec1ff',
    linksUnderline: false,
    ...getFontConfig('Montserrat', '#585858ff')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 18,
    ...getFontConfig('Montserrat', '#585858ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 19,
    ...getFontConfig('Roboto Condensed', '#112c4cff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 11,
    ...getFontConfig('Montserrat', '#585858ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 14,
    ...getFontConfig('Montserrat', '#585858ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: '#58585833',
    borderRadius: 3,
    borderWidth: 3,
    backgroundColor: '#ffffff',
    button: {
      on: true,
      backgroundColor: '#ffffff',
      borderColor: '#58585833',
      borderRadius: 3,
      borderWidth: 2,
      shadow: 'none',
      ...getFontConfig('Ubuntu', '#2E4068')
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: '#5858580d',
      borderColor: '#ffffff33',
      borderRadius: 3,
      borderWidth: 2,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('Roboto Condensed', '#112c4cff', '700')
      },
      info: {
        alignment: 'left',
        fontSize: 12,
        ...getFontConfig('Montserrat', '#585858ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: white,
    borderRadius: 3,
    borderWidth: 2,
    shadow: 'none',
    ...getFontConfig('Montserrat', white)
  },
  detailsButton: {
    on: null,
    backgroundColor: '#00ccff00',
    borderColor: '#528ec1ff',
    borderRadius: 3,
    borderWidth: 2,
    shadow: 'none',
    ...getFontConfig('Montserrat', '#528ec1ff')
  },
  trackButton: {
    on: null,
    backgroundColor: '#00ccff00',
    borderColor: '#528ec1ff',
    borderRadius: 3,
    borderWidth: 2,
    shadow: 'none',
    ...getFontConfig('Montserrat', '#528ec1ff')
  },
  pageScroll: {
    scrollColor: '#00000033',
    borderRadius: 4,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/5c98d791d78ed_logo-180x.png')),
  socialIcons: getSocialIcons(white),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#ffffffff',
    borderColor: '#58585833',
    borderRadius: 3,
    borderWidth: 1,
    sidebarField: 'orderNumbers',
    shadow: 'none',
    ...getFontConfig('Montserrat', '#585858ff')
  },
  footer: {
    backgroundColor: white,
    linksColor: '#528ec1ff',
    linksUnderline: false,
    ...getFontConfig('Montserrat', '#585858'),
    ...getFooterItems()
  },
  header: {
    backgroundColor: '#182e49ff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: '#ffffffff',
    borderColor: '#58585833',
    borderRadius: 3,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff00',
      borderColor: '#58585833',
      borderRadius: 3,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#00ccff00',
        borderColor: '#3c3b84ff',
        borderRadius: 3,
        borderWidth: 2,
        shadow: 'none',
        ...getFontConfig('Montserrat', '#3c3b84ff')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('Montserrat', '#112c4cff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Montserrat', '#585858ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Montserrat', '#585858ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#528ec1ff',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#ffffffff',
    borderColor: '#58585833',
    borderRadius: 3,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff00',
      borderColor: '#58585833',
      borderRadius: 3,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#00ccff00',
        borderColor: '#3c3b84ff',
        borderRadius: 3,
        borderWidth: 2,
        shadow: 'none',
        ...getFontConfig('Montserrat', '#3c3b84ff')
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('Montserrat', '#112c4cff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('Montserrat', '#585858ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Montserrat', '#585858ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#528ec1ff',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
