import {
  StoreRouterConnectingModule,
  routerReducer,
  RouterReducerState
} from '@ngrx/router-store';
import { RouterStateUrl } from './router-serializer';

const STORE_ROUTER_MODULE = StoreRouterConnectingModule.forRoot({
  stateKey: 'router'
});

type State = RouterReducerState<RouterStateUrl>;

export { routerReducer, STORE_ROUTER_MODULE, State };
