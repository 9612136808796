import {
  animate,
  keyframes,
  style,
  transition,
  trigger
} from '@angular/animations';

export const flyInRightAnimation = trigger('flyInRightAnimation', [
  transition('void => *', [
    animate(
      '.2s cubic-bezier(0.10, 1.06, 0.60, 1.34)',
      keyframes([
        style({ opacity: 0, transform: 'translateX(50%)', offset: 0 }),
        style({ opacity: 1, transform: 'translateX(0)', offset: 1 })
      ])
    )
  ])
]);
