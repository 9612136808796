import { TrackingPageModel } from '../tracking-page.model';
import {
  getAppImage,
  getAsset,
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white,
  getProductItems
} from './helpers';

export const modern: TrackingPageModel = {
  languageMenu: getLanguageMenu('PT Serif', '#000000ff'),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('PT Serif', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('PT Serif', '#000000b3')
  },
  background: {
    color: '#cbd5ccff',
    useBackgroundImage: false,
    imageUrl: getAppImage('/assets/img/tracking-page/Img_map_white.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    linksColor: '#59756fff',
    linksUnderline: false,
    backgroundColor: white,
    headerBackground: white,
    footerBackground: white,
    borderColor: '#00000033',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('PT Serif', '#000000b3')
  },
  orderCard: {
    borderRadius: 0,
    borderWidth: 1,
    borderColor: '#00000033',
    backgroundColor: white,
    shadow: 'none',
    footerBackground: white,
    headerBackground: white,
    linksColor: '#59756fff',
    linksUnderline: false,
    ...getFontConfig('PT Serif', '#000000b3')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 17,
    ...getFontConfig('Manrope', '#000000ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 19,
    ...getFontConfig('PT Serif', '#000000b3')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 11,
    ...getFontConfig('PT Serif', '#000000b3')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 14,
    ...getFontConfig('PT Serif', '#000000b3')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: '#c9c9c9ff',
    borderRadius: 0,
    borderWidth: 1,
    backgroundColor: '#ffffff',
    button: {
      on: true,
      backgroundColor: '#72887cff',
      borderColor: '#ffffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      ...getFontConfig('Source Sans Pro', '#ffffffff')
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: '#ffffff00',
      borderColor: '#000000ff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 20,
        ...getFontConfig('PT Serif', '#4d564dff')
      },
      info: {
        alignment: 'center',
        fontSize: 11,
        ...getFontConfig('PT Serif', '#000000b3')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#2b443bff',
    borderColor: '#00000000',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Source Sans Pro', '#ffffffff')
  },
  detailsButton: {
    on: null,
    backgroundColor: '#59756fff',
    borderColor: '#59756f00',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Source Sans Pro', white)
  },
  trackButton: {
    on: null,
    backgroundColor: '#59756fff',
    borderColor: '#ee7227',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Source Sans Pro', white)
  },
  pageScroll: {
    scrollColor: '#00000033',
    borderRadius: 4,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(
    getAsset(
      '/images/themes/5c98cd7589c7c_modern-logo-square-7268e30c-939e-40a2-9ce7-11bc64094c40-120x.png'
    )
  ),
  socialIcons: getSocialIcons('#000000ff'),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#ffffffff',
    borderColor: '#00000033',
    borderRadius: 0,
    borderWidth: 1,
    sidebarField: 'orderNumbers',
    shadow: 'none',
    ...getFontConfig('PT Serif', '#000000b3')
  },
  footer: {
    linksColor: '#59756fff',
    linksUnderline: false,
    backgroundColor: '#ffffffff',
    ...getFontConfig('DM Sans', '#000000'),
    ...getFooterItems()
  },
  header: {
    backgroundColor: '#cbd5ccff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#00000033',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#00000033',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#59756fff',
        borderColor: '#59756f00',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Source Sans Pro', white)
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'center',
        fontSize: 18,
        ...getFontConfig('PT Serif', '#1f2422ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('PT Serif', '#000000b3')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('PT Serif', '#3a5633ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#59756fff',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: white,
    borderColor: '#00000033',
    borderRadius: 0,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: white,
      borderColor: '#00000033',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#59756fff',
        borderColor: '#59756f00',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Source Sans Pro', white)
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'center',
        fontSize: 18,
        ...getFontConfig('PT Serif', '#191c19ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 10,
        ...getFontConfig('PT Serif', '#000000b3')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('PT Serif', '#000000b3')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#59756fff',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
