import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IsOnlineService } from './core/is-online.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { MatomoTracker } from 'ngx-matomo';
import posthog from 'posthog-js';
import { getActiveLanguages } from 'app/helpers';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-toasts-container></app-toasts-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  constructor(
    private isOnlineService: IsOnlineService,
    translate: TranslateService,
    private matomoTracker: MatomoTracker
  ) {
    this.isOnlineService.startOnlineCheck();
    translate.addLangs(getActiveLanguages().map((option) => option.code));
    translate.setDefaultLang('en');
    translate.use('en');
    if (!environment.testMode) {
      this.matomoTracker.setDomains(['*.trackmage.com']);
      this.matomoTracker.setCookieDomain('*.trackmage.com');
      posthog.init(environment.posthogToken, {
        api_host: environment.posthogUrl
      });
    }
  }
}
