import { curry, equals } from 'ramda';
import { createSelector } from '@ngrx/store';
import { filter } from 'rxjs/operators';

export enum StateStatus {
  loading = 'loading',
  success = 'success',
  none = 'none',
  error = 'error'
}

/* eslint-disable */
export interface StateWithStatus {
  status: StateStatus;
  errors: any;
}

export const isStatus = curry(
  (statusToCompare: StateStatus, currentStatus: StateStatus) =>
    equals(statusToCompare, currentStatus)
);

export const isStatusLoading = isStatus(StateStatus.loading);
export const isStatusSuccess = isStatus(StateStatus.success);
export const isStatusError = isStatus(StateStatus.error);
export const isStatusNone = isStatus(StateStatus.none);
export const isStatusSuccessOrError = (status) =>
  isStatusSuccess(status) || isStatusError(status);

export const toggleStatus = curry((status: StateStatus, state, action) => {
  return {
    ...state,
    status,
    errors: isStatusError(status) ? action.payload : null
  };
});

export const toggleStatusLoading = (state) =>
  toggleStatus(StateStatus.loading, state, {});
export const toggleStatusSuccess = <S extends StateWithStatus>(
  state: S
): S => ({
  ...state,
  status: StateStatus.success,
  errors: null
});
export const toggleStatusError = (state, action) =>
  toggleStatus(StateStatus.error, state, action);
export const toggleStatusNone = (state) =>
  toggleStatus(StateStatus.none, state, {});

export const getInitialStateWithStatus = <T extends Object>(
  state: T = {} as T
): T & StateWithStatus =>
  Object.assign({}, { status: StateStatus.none, errors: null }, state);

const selectStatusState = <T extends StateWithStatus>(state) => state.status;
const selectErrorsState = <T extends StateWithStatus>(state) => state.errors;
const selectIsStatusLoadingState = createSelector(
  selectStatusState,
  isStatusLoading
);
const selectIsStatusErrorState = createSelector(
  selectStatusState,
  isStatusError
);
const selectIsStatusSuccessState = createSelector(
  selectStatusState,
  isStatusSuccess
);
const selectIsStatusNoneState = createSelector(selectStatusState, isStatusNone);

export const getStatusStateSelectors = () => ({
  selectErrorsState,
  selectStatusState,
  selectIsStatusSuccessState,
  selectIsStatusErrorState,
  selectIsStatusLoadingState,
  selectIsStatusNoneState
});

export function waitForLoaded() {
  return filter(
    (status: StateStatus) => isStatusError(status) || isStatusSuccess(status)
  );
}

export const stateWithStatusHelpers = {
  toggleStatusLoading,
  toggleStatusSuccess,
  toggleStatusError,
  toggleStatusNone
};
