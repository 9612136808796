import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white
} from './helpers';

export const seoul: TrackingPageModel = {
  languageMenu: getLanguageMenu('Montserrat', '#000000ff', '700'),
  searchPageInput: {
    backgroundColor: '#f7f7f7ff',
    borderColor: '#ced4da',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Ubuntu', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Montserrat', '#000000ff', '700')
  },
  background: {
    color: white,
    useBackgroundImage: true,
    imageUrl: getAsset('/images/themes/seoul.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: '#f7f7f7ff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#f7f7f7ff',
    footerBackground: '#f7f7f7ff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    ...getFontConfig('Montserrat', '#393939ff', '700')
  },
  orderCard: {
    backgroundColor: '#ffffff',
    linksColor: '#5780ff',
    linksUnderline: false,
    headerBackground: '#ffffff',
    footerBackground: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Montserrat', '#393939ff', '700')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Montserrat', '#000000', '800')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 10,
    ...getFontConfig('Montserrat', '#959595ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 14,
    ...getFontConfig('Montserrat', '#e14141ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    scroll: false,
    itemsLimit: 4,
    borderColor: '#fff1f5ff',
    borderWidth: 1,
    borderRadius: 0,
    backgroundColor: '#ffffff',
    shadow: 'none',
    button: {
      on: true,
      backgroundColor: '#ff975aff',
      borderColor: '#2E4068',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      ...getFontConfig('Montserrat', white, '700')
    },
    itemCard: {
      backgroundColor: '#ffffff00',
      borderColor: '#2E4068',
      borderRadius: 4,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 14,
        ...getFontConfig('Montserrat', '#393939ff', '800')
      },
      info: {
        alignment: 'left',
        fontSize: 11,
        ...getFontConfig('Montserrat', '#a9a9a9ff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#ffffff00',
    borderColor: '#ffffffff',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Montserrat', '#000000ff', '700')
  },
  detailsButton: {
    backgroundColor: '#36cbc3ff',
    borderColor: '#36cbc3ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Montserrat', white, '700')
  },
  trackButton: {
    backgroundColor: '#ff975aff',
    borderColor: '#ff975aff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Montserrat', white, '700')
  },
  pageScroll: {
    scrollColor: '#2E4068',
    borderRadius: 2,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(getAsset('/images/themes/seoul-logo.png')),
  socialIcons: getSocialIcons('#ff975aff'),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#ffffff',
    borderColor: '#CED2DC',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    sidebarField: null,
    ...getFontConfig('Montserrat', '#000000ff', '700')
  },
  footer: {
    linksColor: '#5780ff',
    linksUnderline: false,
    backgroundColor: white,
    ...getFooterItems(),
    ...getFontConfig('Ubuntu', '#2E4068')
  },
  header: {
    backgroundColor: '#ffffff'
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  sidebarProductsFeed: {
    on: false,
    backgroundColor: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#36cbc3ff',
        borderColor: '#5780ff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Montserrat', white, '800')
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Montserrat', '#000000', '800')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Ubuntu', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Montserrat', '#f89317ff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('seoul'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#607FF7',
      arrowSize: 24
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#ffffff',
    borderColor: '#d5d9e1',
    borderRadius: 4,
    borderWidth: 1,
    shadow: 'none',
    card: {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        backgroundColor: '#36cbc3ff',
        borderColor: '#5780ff',
        borderRadius: 0,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Montserrat', white, '800')
      },
      buttonText: 'BUY NOW',
      title: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Montserrat', '#000000', '800')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Ubuntu', '#828282')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Montserrat', '#f89317ff', '700')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('seoul'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#607FF7',
      arrowSize: 24
    }
  },
  seoTitle: ''
};
