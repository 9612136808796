import { fileDownloadFactory, blobDownloadFactory } from './file-uploader';
export * from './functions';
export * from './store';
export * from './base-classes';
export * from './backend-transformers';
export * from './sort-and-filter-helpers';
export * from './input-mask';
export * from './copy-link';
export * from './date-functions';
export * from './matomo';

const download = fileDownloadFactory();
const downloadFile = blobDownloadFactory();

export { download, downloadFile };
