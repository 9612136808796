import { TrackingPageModel } from '../tracking-page.model';
import { getAsset, getProductItems } from './helpers';
import {
  getImage,
  getFontConfig,
  getSocialIcons,
  getFooterItems,
  getLogo,
  getLanguageMenu,
  white
} from './helpers';

export const brooklyn: TrackingPageModel = {
  languageMenu: getLanguageMenu(),
  searchPageInput: {
    backgroundColor: white,
    shadow: 'none',
    borderColor: '#ced4da',
    borderWidth: 1,
    borderRadius: 4,
    ...getFontConfig('Crimson Text', '#6d788c')
  },
  searchPageTitle: {
    fontSize: 18,
    ...getFontConfig('Crimson Text', '#222323ff')
  },
  background: {
    color: white,
    useBackgroundImage: false,
    imageUrl: getImage('/assets/img/tracking-page/Img_map_white.png'),
    backgroundMode: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    backgroundColor: '#f7f7f7ff',
    headerBackground: '#f7f7f7ff',
    footerBackground: '#f7f7f7ff',
    borderColor: '#00ccff00',
    borderRadius: 50,
    borderWidth: 0,
    shadow: 'none',
    trackingNumberField: 'trackingNumber',
    linksColor: '#a26b25ff',
    linksUnderline: false,
    ...getFontConfig('Crimson Text', '#222323ff')
  },
  orderCard: {
    borderRadius: 0,
    borderWidth: 1,
    borderColor: '#ecececff',
    backgroundColor: white,
    shadow: 'none',
    footerBackground: white,
    headerBackground: white,
    linksColor: '#5780ff',
    linksUnderline: false,
    ...getFontConfig('Crimson Text', '#222323ff')
  },
  orderNumber: {
    alignment: 'center',
    fontSize: 17,
    ...getFontConfig('Inter', '#393939ff')
  },
  orderStatus: {
    alignment: 'center',
    fontSize: 20,
    ...getFontConfig('Inter', '#393939ff')
  },
  orderLastUpdated: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Inter', '#959595ff')
  },
  notYetShippedItemsTitle: {
    alignment: 'center',
    fontSize: 15,
    ...getFontConfig('Inter', '#c52828ff')
  },
  orderStatusOn: true,
  orderNumberOn: true,
  orderLastUpdatedOn: true,
  orderItemsFrame: {
    on: true,
    shadow: 'none',
    borderColor: '#ffffffff',
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: '#f7f7f7ff',
    button: {
      on: true,
      backgroundColor: '#222323',
      borderColor: '#222323ff',
      borderRadius: 22,
      borderWidth: 1,
      shadow: 'none',
      ...getFontConfig('Montserrat', white)
    },
    itemsLimit: 4,
    scroll: false,
    itemCard: {
      backgroundColor: '#ffffff00',
      borderColor: '#ffffffff',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      title: {
        alignment: 'left',
        fontSize: 21,
        ...getFontConfig('Inter', '#222323ff')
      },
      info: {
        alignment: 'left',
        fontSize: 15,
        ...getFontConfig('Inter', '#8a8a8aff')
      },
      showAttributes: false
    }
  },
  searchButton: {
    on: true,
    backgroundColor: '#22232300',
    borderColor: '#222323ff',
    borderRadius: 22,
    borderWidth: 1,
    shadow: 'none',
    ...getFontConfig('Montserrat', '#222323ff')
  },
  detailsButton: {
    on: null,
    backgroundColor: '#222323ff',
    borderColor: '#5780ff',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Montserrat', white)
  },
  trackButton: {
    on: null,
    backgroundColor: '#222323ff',
    borderColor: '#ee7227',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    ...getFontConfig('Montserrat', white)
  },
  pageScroll: {
    scrollColor: '#00000033',
    borderRadius: 4,
    backgroundColor: '#ffffff00'
  },
  scripts: {
    head: null,
    body: null
  },
  logo: getLogo(
    getAsset('/images/themes/5cb80f99c92bb_logo-180x-2x-180x-2x.png')
  ),
  socialIcons: getSocialIcons('#222323ff'),
  sidebar: {
    parcelIcon: 'icon-parcel',
    groupIcon: 'icon-track_number',
    backgroundColor: '#ffffffff',
    borderColor: '#ecececff',
    borderRadius: 1,
    borderWidth: 1,
    sidebarField: 'orderNumbers',
    shadow: 'none',
    ...getFontConfig('Inter', '#393939ff')
  },
  footer: {
    backgroundColor: white,
    linksColor: '#a26b25ff',
    linksUnderline: false,
    ...getFooterItems(),
    ...getFontConfig('Inter Tight', '#222323ff')
  },
  header: {
    backgroundColor: white
  },
  faviconUrl: '/assets/favicon/favicon-32x32.png',
  defaultThemeId: 'brooklyn',
  sidebarProductsFeed: {
    on: true,
    backgroundColor: '#f7f7f7ff',
    borderColor: '#00ccff00',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#f7f7f7ff',
      borderColor: '#00ccff00',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        on: null,
        backgroundColor: '#222323ff',
        borderColor: '#5780ff',
        borderRadius: 22,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Montserrat', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 15,
        ...getFontConfig('Inter', '#222323ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 12,
        ...getFontConfig('Inter', '#FA0000ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 20,
        ...getFontConfig('Inter', '#222323ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('sidebar'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#222323ff',
      arrowSize: 40
    }
  },
  mainProductsFeed: {
    on: true,
    backgroundColor: '#f7f7f7ff',
    borderColor: '#00ccff00',
    borderRadius: 0,
    borderWidth: 0,
    shadow: 'none',
    card: {
      backgroundColor: '#f7f7f7ff',
      borderColor: '#00ccff00',
      borderRadius: 0,
      borderWidth: 0,
      shadow: 'none',
      button: {
        on: null,
        backgroundColor: '#222323ff',
        borderColor: '#5780ff',
        borderRadius: 22,
        borderWidth: 0,
        shadow: 'none',
        ...getFontConfig('Montserrat', white)
      },
      buttonText: 'Buy Now',
      title: {
        alignment: 'center',
        fontSize: 16,
        ...getFontConfig('Inter', '#222323ff')
      },
      regularPrice: {
        alignment: 'center',
        fontSize: 13,
        ...getFontConfig('Inter', '#FA0000ff')
      },
      discountedPrice: {
        alignment: 'center',
        fontSize: 20,
        ...getFontConfig('Inter', '#222323ff')
      },
      imageBorderRadius: 0,
      imageHeight: 120,
      imageWidth: 120
    },
    feedUrl: '',
    productItems: getProductItems('main'),
    sliderOptions: {
      autoplay: true,
      autoplayDelay: 3000,
      loop: true,
      arrowColor: '#222323ff',
      arrowSize: 40
    }
  },
  seoTitle: ''
};
